import React, { useState } from "react";
import { Beneficiary } from "../../../models/beneficiary";
import { useTranslation } from "react-i18next";
import ImagePreview from "../../../components/image-preview/ImagePreview";
import { Checkbox, CircularProgress, Switch } from "@material-ui/core";
import ActionTable from "../../../components/action-table/ActionTable";
import { useHistory } from "react-router-dom";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import BeneficiaryService from "../../../services/beneficiary-service";
import { toastr } from "react-redux-toastr";
import { Client } from "../../../models/client";
import { connect } from "react-redux";
import { User } from "../../../models/user";
import ModalDelete from "../../../components/modal/ModalDelete";

interface ItemBeneficiaryProps {
    beneficiary: Beneficiary;
    context?: Client | null;
    user: User;
    handleCheckbox: (e: any) => void;
    checkboxs: any;
    setErrorApi: (error: ErrorFormData) => void;
    refreshKpis: () => void;
    handleDeleted: () => void;
}

const ItemBeneficiary: React.FunctionComponent<ItemBeneficiaryProps> = ({
    beneficiary,
    context,
    user,
    handleCheckbox,
    checkboxs,
    setErrorApi,
    refreshKpis,
    handleDeleted,
}) => {
    const { t } = useTranslation();
    //const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    const idBenefeciary = beneficiary.id.toString();
    const [switchLoader, setSwitchLoader] = useState(false);
    const [actifValue, setActifValue] = useState<boolean>(
        beneficiary.activated || false
    );
    const [isOpen, setIsOpen] = useState(false);
    const editBenef = () => {
        let url = `/app/beneficiary/${beneficiary.id}`;
        if (context) {
            url += `/${context.id}`;
        }
        history.push(url);
    };
    const deleteProvider = () => {
        setIsOpen(true);
    };

    //Handle change tender switch beneficiary
    const changeActifBenefeciary = async (actif: boolean) => {
        setSwitchLoader(true);
        try {
            const beneficiaryService = new BeneficiaryService();
            await beneficiaryService.patch(
                {
                    activated: actif,
                },
                beneficiary.id
            );
            toastr.success(t("success"), t("success_save_benefeciary"));
            setActifValue(actif);
            refreshKpis();
        } catch (error) {
            setActifValue(!actif);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setSwitchLoader(false);
    };

    const isReader = () => {
        return context && user.isClient() && user.isReader(context)
            ? true
            : false;
    };

    return (
        <>
            <BodyTableRow key={beneficiary.id}>
                {!context && (
                    <BodyTableCell align="center">
                        <Checkbox
                            id={idBenefeciary}
                            checked={
                                checkboxs.selections.includes(idBenefeciary)
                                    ? true
                                    : false
                            }
                            onChange={(e) => handleCheckbox(e)}
                        />
                    </BodyTableCell>
                )}
                <BodyTableCell align="center">
                    {beneficiary.client?.company_name}
                </BodyTableCell>
                <BodyTableCell className="img-index-beneficiary ">
                    <div className="upload-image-table">
                        <ImagePreview
                            size="litle"
                            existingImageUrl={beneficiary.img_url}
                            onUpdateImage={() => null}
                        />
                    </div>
                </BodyTableCell>
                <BodyTableCell align="center" className="to-upper">
                    <div className="link-table" onClick={() => editBenef()}>
                        {beneficiary.name}
                    </div>
                </BodyTableCell>
                <BodyTableCell align="center">
                    {!switchLoader ? (
                        <Switch
                            onChange={(e) =>
                                changeActifBenefeciary(e.target.checked)
                            }
                            checked={!!actifValue}
                            name={idBenefeciary}
                            disableRipple
                            disabled={isReader()}
                            className="switch-ios"
                        />
                    ) : (
                        <CircularProgress />
                    )}
                </BodyTableCell>
                <BodyTableCell
                    align="right"
                    className="beneficiary-actions-row"
                >
                    {!isReader() && (
                        <>
                            <ActionTable
                                deleteAction={() => deleteProvider()}
                                updateAction={() => editBenef()}
                            />

                            <ModalDelete
                                isOpen={isOpen}
                                closeDialog={() => setIsOpen(false)}
                                itemType="benefeciary"
                                itemCode={beneficiary.id.toString()}
                                itemName={beneficiary.name}
                                handleDelete={() => handleDeleted()}
                                isDeleteAction
                            />
                        </>
                    )}
                </BodyTableCell>
            </BodyTableRow>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(ItemBeneficiary);
