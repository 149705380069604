import React, { useState } from "react";
import { Vehicle } from "../../../models/vehicle";
import "./ItemVehicle.scss";
import ImagePreview from "../../../components/image-preview/ImagePreview";
import LicencePlate from "../../../components/licence-plate/licencePlate";
import { VehicleOption } from "../../../models/vehicle-option";
import ImgInteriorVehicle from "../../../components/vehicles/img-interior-vehicle/ImgInteriorVehicle";
import ImgExteriorColor from "../../../components/vehicles/img-exterior-vehicle/ImgExteriorColor";
import ActionTable from "../../../components/action-table/ActionTable";
import { useHistory } from "react-router-dom";
import VehicleService from "../../../services/vehicle-service";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import ModalDelete from "../../../components/modal/ModalDelete";

interface IItemVehicle {
    vehicle: Vehicle;
    onUpdateSuccess?: (vehicle: Vehicle) => void;
    onDeleteSuccess: () => void;
}

const ItemVehicle: React.FunctionComponent<IItemVehicle> = ({
    vehicle,
    onDeleteSuccess,
}) => {
    const history = useHistory();
    const vehicleService = new VehicleService();
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const renderOptions = (options: Array<VehicleOption>) => {
        return options.map((option: VehicleOption) => (
            <div
                className="flex_column"
                key={`vehicle_option_detailed_${option.id}`}
            >
                <span>{option.label}</span>
            </div>
        ));
    };

    const editVehicle = () => {
        history.push(`vehicle/${vehicle.id}/edit`);
    };

    const deleteVehicle = async () => {
        await vehicleService.delete(vehicle.id);
        setIsOpen(false);
        toastr.success(t("success"), t("success_deleted_vehicle"));
        onDeleteSuccess();
    };

    return (
        <>
            <BodyTableRow>
                <BodyTableCell align="center">
                    {vehicle.type ? (
                        <div className="item-type">
                            <img
                                src={vehicle.type.getIcon()}
                                alt="Vehicle type"
                            />
                            <span>{vehicle.type.label}</span>
                        </div>
                    ) : (
                        "-"
                    )}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {vehicle.brand ? (
                        <div className="item-brand">
                            <ImagePreview
                                size="litle"
                                existingImageUrl={vehicle.brand.img_url}
                                onUpdateImage={() => null}
                            />
                            <span>{vehicle.brand.name}</span>
                        </div>
                    ) : (
                        "-"
                    )}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {vehicle.model ? vehicle.model.label : "-"}
                </BodyTableCell>
                <BodyTableCell align="center">{vehicle.year}</BodyTableCell>
                <BodyTableCell align="center">
                    <ImgExteriorColor exteriorColor={vehicle.exterior_color} />
                </BodyTableCell>
                <BodyTableCell align="center">
                    <ImgInteriorVehicle
                        interiorType={vehicle.interior_type}
                        interiorColor={vehicle.interior_color}
                    />
                </BodyTableCell>
                <BodyTableCell align="center">
                    {vehicle.seats_available}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {vehicle.options ? renderOptions(vehicle.options) : "-"}
                </BodyTableCell>
                <BodyTableCell align="center">Assurance 1</BodyTableCell>
                <BodyTableCell align="center">
                    <div
                        className="link-table"
                        onClick={() =>
                            history.push(`provider/${vehicle.provider?.id}`)
                        }
                    >
                        {vehicle.provider?.company_name}
                    </div>
                </BodyTableCell>
                <BodyTableCell align="center">
                    <LicencePlate licencePlate={vehicle.licence_plate} />
                </BodyTableCell>
                <BodyTableCell align="right">
                    <ActionTable
                        deleteAction={() => setIsOpen(true)}
                        updateAction={() => editVehicle()}
                    />
                </BodyTableCell>
                <ModalDelete
                    isOpen={isOpen}
                    closeDialog={() => setIsOpen(false)}
                    itemType="vehicles"
                    itemCode={vehicle.licence_plate}
                    itemName={vehicle.type?.label}
                    handleDelete={() => deleteVehicle()}
                    isDeleteAction
                />
            </BodyTableRow>
        </>
    );
};

export default ItemVehicle;
