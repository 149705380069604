import React, { useState } from "react";
import LoginForm from "./LoginForm";
import { useTranslation } from "react-i18next";
import "./Login.scss";
import { useLocation } from "react-router-dom";

export default function Login() {
    const { t } = useTranslation();
    const query = new URLSearchParams(useLocation().search);
    const param = query.get("tab");
    const [tab, setTab] = useState<string>(param ? param : "admin");

    return (
        <div className="container-login-page">
            <div className="login-page">
                <div className="container-img">
                    <img src="/images/login/images/image-login.png" alt="" />
                </div>
                <div className="container-login">
                    <div className="container-text">
                        <h1 className="to-upper text">{t("login")}</h1>
                    </div>
                    <div className="login flex_center">
                        <div className="form-container">
                            <div className="admin-logo-section">
                                <img
                                    className="logo"
                                    src={`/images/login/images/${tab}-logo.png`}
                                    alt=""
                                />
                            </div>
                            <div className="form-content">
                                <LoginForm tab={tab}></LoginForm>
                            </div>
                        </div>
                    </div>
                    {/* <div className="container-text">
                        <h1 className="text">{t("no_account")}</h1>
                    </div> */}
                </div>
            </div>
            <div className="tabs-section">
                <div className="tabs-content">
                    <div
                        className="tab provider"
                        onClick={() => setTab("provider")}
                    >
                        <img
                            src="/images/login/images/provider-tab.png"
                            alt=""
                        />
                    </div>
                    <div className="tab admin" onClick={() => setTab("admin")}>
                        <img src="/images/login/images/admin-tab.png" alt="" />
                    </div>
                    <div
                        className="tab client"
                        onClick={() => setTab("client")}
                    >
                        <img src="/images/login/images/client-tab.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}
