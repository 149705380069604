import React, { useState } from "react";
import { Driver } from "../../../models/driver";
import { useTranslation } from "react-i18next";
import ImagePreview from "../../../components/image-preview/ImagePreview";
import { CircularProgress, Switch } from "@material-ui/core";
import ActionTable from "../../../components/action-table/ActionTable";
import { useHistory } from "react-router-dom";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import DriverService from "../../../services/driver-service";
import { toastr } from "react-redux-toastr";
import ActionButton from "../../../components/action-button/ActionButton";
import "./ItemDriver.scss";
import ModalDelete from "../../../components/modal/ModalDelete";
import { ReactSVG } from "react-svg";
import DriverNote from "../../../components/popups/driver-note/DriverNote";
import Modal from "../../../components/modal/Modal";

interface ItemBeneficiaryProps {
    driver: Driver;
    setErrorApi: (error: ErrorFormData) => void;
    fetchKpis: () => void;
    handleDeleted: () => void;
}

const ItemDriver: React.FunctionComponent<ItemBeneficiaryProps> = ({
    driver,
    setErrorApi,
    fetchKpis,
    handleDeleted,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const idDriver = driver.id.toString();
    const [switchLoader, setSwitchLoader] = useState(false);
    const [isTenderValue, setIsTenderValue] = useState<boolean>(
        driver.is_tender || false
    );
    const [isOpen, setIsOpen] = useState(false);
    const [errorModalIsOpen, setErrorModalIsOpen] = useState<string | null>(null);
    const [isShown, setIsShown] = useState(false);
    const [showNote, setShowNote] = useState(false);

    const editBenef = () => {
        history.push(`driver/${driver.id}`);
    };

    //Handle change tender switch driver
    const changeIsTenderDriver = async (isTender: boolean) => {
        setSwitchLoader(true);
        try {
            const driverService = new DriverService();
            await driverService.patch(
                {
                    is_tender: isTender,
                },
                driver.id
            );
            toastr.success(t("success"), t("success_save_drive"));
            setIsTenderValue(isTender);
            fetchKpis();
        } catch (error) {
            setIsTenderValue(!isTender);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setSwitchLoader(false);
    };

    const resetPasswordDriver = async (driver: Driver) => {
        const driverService = new DriverService();
        try {
            await driverService.sendDemandResetPassword(driver.email);
            toastr.success(t("success"), t("success_demand_password_driver"));
        } catch (error) {
            toastr.success(t("error"), t("unexpected_error"));
        }
    };

    const deleteDriver = async () => {
        const driverService = new DriverService();
        try {
            await driverService.delete(driver.id);
            handleDeleted();
            setIsOpen(false);
            toastr.success(t("success"), t("success_delete_driver"));
        } catch (error) {
            if (error.response.data?.error) {
                //display specific translated message
                let errorMessage:string = error.response.data?.error
                setIsOpen(false)
                setErrorModalIsOpen(errorMessage)
            } else {
                toastr.error(t("error"), t("unexpected_error"));
            }
        }
    };

    //var txt:string='0650562546'
    //var rst=txt.replace(/(.{2})(?!$)/g,"$1 ");
    //console.log(rst,'new formate of your phone number')

    return (
        <>
            <BodyTableRow key={driver.id}>
                <BodyTableCell className="img-index-driver">
                    <div className="upload-image-table">
                        <ImagePreview
                            size="litle"
                            existingImageUrl={driver.img_profil_url}
                            onUpdateImage={() => null}
                        />
                    </div>
                </BodyTableCell>
                <BodyTableCell align="center">
                    <div
                        className="link-table"
                        onClick={() => history.push(`driver/${driver.id}`)}
                    >
                        {driver.last_name}
                    </div>
                </BodyTableCell>
                <BodyTableCell align="center">
                    {driver.first_name}
                </BodyTableCell>
                <BodyTableCell align="center">
                    <div style={{ whiteSpace: "nowrap" }}>
                        {"+" +
                            driver.phone.slice(0, 2) +
                            " " +
                            driver.phone.charAt(2) +
                            " " +
                            driver.phone
                                .slice(3, driver.phone.length)
                                .replace(/(.{2})(?!$)/g, "$1 ")}
                    </div>
                </BodyTableCell>
                <BodyTableCell align="center">{driver.email}</BodyTableCell>
                <BodyTableCell align="center">
                    {driver.provider.company_name}
                </BodyTableCell>
                <BodyTableCell align="center">
                    <div className="driver-languages-item">
                        {driver.languages.map((language, index) => (
                            <span
                                key={`driver_${driver.id}_${language.id}_item`}
                            >
                                {" "}
                                {index > 0 ? " " : ""} {language.label}
                            </span>
                        ))}
                    </div>
                </BodyTableCell>
                <BodyTableCell align="center">
                    <div
                        className="password-reset flex_center"
                        onMouseEnter={() => setIsShown(true)}
                        //onMouseLeave={() => setIsShown(false)}
                    >
                        {!isShown && (
                            <>
                                <span style={{ paddingRight: "10px" }}>
                                    {t("reset")}{" "}
                                </span>
                                <img
                                    src="/images/drivers/icon-reset.png"
                                    alt={"reset"}
                                />
                            </>
                        )}
                        {isShown && (
                            <ActionButton
                                width="180px"
                                marginLeft="0"
                                onClickAction={() =>
                                    resetPasswordDriver(driver)
                                }
                            >
                                Rénitialiser
                            </ActionButton>
                        )}
                    </div>
                </BodyTableCell>
                <BodyTableCell align="center">
                    <ReactSVG
                        src="/images/app_icons/chat.svg"
                        onMouseEnter={() => setShowNote(true)}
                        onMouseLeave={() => setShowNote(false)}
                    />
                    {showNote && <DriverNote driver={driver} />}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {!switchLoader ? (
                        <Switch
                            onChange={(e) =>
                                changeIsTenderDriver(e.target.checked)
                            }
                            checked={!!isTenderValue}
                            name={idDriver}
                            disableRipple
                            className="switch-ios"
                        />
                    ) : (
                        <CircularProgress />
                    )}
                </BodyTableCell>
                <BodyTableCell align="right" className="driver-actions-row">
                    <ActionTable
                        // deleteAction={() => console.log(driver)}
                        deleteAction={() => setIsOpen(true)}
                        updateAction={() => editBenef()}
                    />
                </BodyTableCell>
                <ModalDelete
                    isOpen={isOpen}
                    closeDialog={() => setIsOpen(false)}
                    itemType="driver"
                    itemCode={driver.id.toString()}
                    itemName={driver.last_name + " " + driver.first_name}
                    handleDelete={() => deleteDriver()}
                    isDeleteAction
                />
                <Modal
                    label={t("impossible_delete_driver")}
                    isOpen={errorModalIsOpen !== null}
                    closeModal={() => setErrorModalIsOpen(null)}
                >
                    <div className="modal-redirect">
                        <div className="title-section">
                            <h2 className="title-content to-upper">
                                {t(typeof errorModalIsOpen == 'string' ? errorModalIsOpen : '')}
                            </h2>
                        </div>
                    </div>
                </Modal>
            </BodyTableRow>
        </>
    );
};

export default ItemDriver;
