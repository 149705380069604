import React, { useState } from "react";
import { VehicleBrand } from "../../../../models/vehicle-brand";
import IconEdit from "@material-ui/icons/Edit";
import IconClear from "@material-ui/icons/Delete";
import IconCheck from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorFormData } from "../../../../models/interfaces/errorForm";
import BrandService from "../../../../services/brand-service";
import { toastr } from "react-redux-toastr";
import { ErrorForm } from "../../../../components/ErrorForm";
import ImagePreview from "../../../../components/image-preview/ImagePreview";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import "./ItemBrand.scss"

interface IItemBrand {
    vehicleBrand: VehicleBrand;
    onUpdateSuccess: (vehicleBrand: VehicleBrand) => void;
    onDeleteSuccess: () => void;
}

interface IFormBrand {
    name: string;
    img_base64: any;
}

const ItemBrand: React.FunctionComponent<IItemBrand> = ({vehicleBrand, onUpdateSuccess, onDeleteSuccess,}) => {
    const [editing, setIsEditing] = useState<boolean>(false);
    const [disabledForm, setDisabledForm] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        control,
        reset,
    } = useForm<IFormBrand>();
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const onSubmit = async (data: any, e: any) => {
        setErrorApi(new ErrorFormData());
        const brandService = new BrandService();
        try {
            setDisabledForm(true);
            const editedVehicleBrand = await brandService.update(
                data,
                vehicleBrand.id
            );
            onUpdateSuccess(editedVehicleBrand);
            toastr.success(t("success"), t("success_updated_brand"));
            setDisabledForm(false);
            cancelEditing();
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    const deleteBrand = async () => {
        setErrorApi(new ErrorFormData());
        const brandService = new BrandService();
        try {
            await brandService.delete(vehicleBrand.id);
            onDeleteSuccess();
            toastr.success(t("success"), t("success_deleted_brand"));
            cancelEditing();
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    const cancelEditing = () => {
        setIsEditing(false);
        setValue("img_base64", null);
        reset();
    };

    return (
        <>
            {!editing ? (
                <>
                    <td>{vehicleBrand.name}</td>
                    <td align={"center"}>
                        {vehicleBrand.img_url ? (
                            <ImagePreview
                                size="little"
                                existingImageUrl={vehicleBrand.img_url}
                                onUpdateImage={() => null}
                            />
                        ) : ("")}
                    </td>
                    <td className="actions">
                        <IconClear onClick={() => deleteBrand()} className="delete-action"/>
                        <IconEdit onClick={() => setIsEditing(true)} className="edit-action"/>
                        <ErrorForm errorApi={errorApi} />
                    </td>
                </>
            ) : (
                <>
                    <td className="actions" colSpan={3}>
                        <form
                            className="form_row align_items_center"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <InputControlled
                                name="name"
                                label={t("name")}
                                value={vehicleBrand.name}
                                required={true}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                            />

                            <input type="hidden" name="img_base64" ref={register()} />
                            <ImagePreview
                                disabledForm={disabledForm}
                                size="little"
                                existingImageUrl={vehicleBrand.img_url}
                                canUpdate={true}
                                onUpdateImage={(imgBase64) => setValue("img_base64", imgBase64)}
                            />

                            <ErrorForm errorApi={errorApi} />

                            <button className="submit_icon" type="submit">
                                <IconCheck />
                            </button>
                            <CloseIcon onClick={() => cancelEditing()} className="cancel-edit" />
                        </form>
                    </td>
                </>
            )}
        </>
    );
};

export default ItemBrand;
