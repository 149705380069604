import { Deserializable } from "./interfaces/deserializable";

export class VehicleType implements Deserializable {
    public id: number;
    public label: string;
    public nb_vehicles?: number;

    constructor(data: any) {
        this.id = data.id;
        this.label = data.label;
        this.nb_vehicles = data.nb_vehicles ? data.nb_vehicles : null;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    getIcon() {
        if (this.id === 1) {
            return "/images/vehicle-types/icons/bus.png";
        } else if (this.id === 2) {
            return "/images/vehicle-types/icons/bus.png";
        } else if (this.id === 3) {
            return "/images/vehicle-types/icons/minivan.png";
        } else if (this.id === 4) {
            return "/images/vehicle-types/icons/limousine.png";
        } else if (this.id === 5) {
            return "/images/vehicle-types/icons/berline.png";
        } else if (this.id === 6) {
            return "/images/vehicle-types/icons/moto.png";
        }
    }

    getIconKpi() {
        if (this.id === 1) {
            return "images/vehicle-types/indicators/bus.svg";
        } else if (this.id === 2) {
            return "images/vehicle-types/indicators/bus.svg";
        } else if (this.id === 3) {
            return "images/vehicle-types/indicators/minivan.svg";
        }

        return "images/vehicle-types/indicators/car.svg";
    }
}
