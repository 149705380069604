import { Deserializable } from "./interfaces/deserializable";
import { Folder } from "./folder";
import { VehicleOption } from "./vehicle-option";
import { Assignment } from "./assignment";
import { Language } from "./language";
import { Incident } from "./incident";
import { VehicleType } from "./vehicle-type";

const TRANSFER_TYPE_STANDARD = "standard";
const TRANSFER_TYPE_PROVISION = "provision";

export class Transfer implements Deserializable {
    public id: number;
    public code: string;
    public folder_id: string;
    public folder: Folder | null;
    public type: string;
    public nb_passenger: number;
    public departure_location: string;
    public departure_datetime?: Date | null;
    public arrival_location: string;
    public arrival_datetime?: Date | null;
    public infos: string;
    public status?: string;
    public provider_id: number;
    public vehicle_type_id: number;
    public vehicle_type: VehicleType;
    public created_at: Date;
    public updated_at: Date;
    public passengers?: Array<Passenger> | null;
    public assignment?: Assignment;
    public assignments?: Assignment[] | null;
    public route_points?: Array<RoutePoint> | null;
    public incidents: Incident[] | null;
    public rights: TransferRights | null;
    public options?: Array<VehicleOption> | null;
    public is_tender: boolean;
    public tender_price?: number | null;
    public min_estimate?: number | null;
    public max_estimate?: number | null;
    public tender_status?: string;
    public steps?: Array<Step> | null;
    public free_note: string;
    public provider_price?: number | null;
    public client_price?: number | null;
    public departure_postal_code?: string | null;
    public arrival_postal_code?: string | null;
    public passengers_prevent_at?: Date | null;
    public is_confirmed: boolean;

    constructor(data: any) {
        this.id = data.id;
        this.code = data.code;
        this.folder_id = data.folder_id;
        this.folder = data.folder ? new Folder(data.folder) : null;
        this.status = data.status;
        this.tender_status = data.tender_status;
        this.type = data.type;
        this.nb_passenger = data.nb_passenger;
        this.departure_location = data.departure_location;
        this.departure_datetime = data.departure_datetime
            ? new Date(data.departure_datetime)
            : null;
        this.arrival_location = data.arrival_location;
        this.arrival_datetime = data.arrival_datetime
            ? new Date(data.arrival_datetime)
            : null;
        this.infos = data.infos;
        this.provider_id = data.provider_id;
        this.vehicle_type_id = data.vehicle_type_id;
        this.vehicle_type = data.vehicle_type;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);

        this.passengers = data.passengers
            ? data.passengers.map(
                  (passenger: Passenger) => new Passenger(passenger)
              )
            : null;
        if (data.assignment) {
            this.assignment = new Assignment(data.assignment);
        }
        this.assignments = data.assignments
            ? data.assignments.map(
                  (assign: Assignment) => new Assignment(assign)
              )
            : null;
        this.route_points = data.route_points
            ? data.route_points.map(
                  (point: RoutePoint) => new RoutePoint(point)
              )
            : null;
        this.options = data.options
            ? data.options.map(
                  (option: VehicleOption) => new VehicleOption(option)
              )
            : null;

        this.incidents =
            data.incidents &&
            data.incidents.map((incident: any) => new Incident(incident));
        this.rights = new TransferRights(data.rights);
        this.is_tender = data.is_tender;
        this.tender_price = data.tender_price;
        this.min_estimate = data.min_estimate;
        this.max_estimate = data.max_estimate;

        this.steps = data.steps
            ? data.steps.map((step: Step) => new Step(step))
            : null;

        this.free_note = data.free_note;
        this.provider_price = data.provider_price;
        this.client_price = data.client_price;
        this.departure_postal_code = data.departure_postal_code;
        this.arrival_postal_code = data.arrival_postal_code;
        if (data.passengers_prevent_at) {
            this.passengers_prevent_at = new Date(data.passengers_prevent_at);
        }
        this.is_confirmed = data.is_confirmed;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    isEnded(): boolean {
        return this.status === "ended";
    }

    isCancelled(): boolean {
        return this.status === "cancelled";
    }

    unChangeable(): boolean {
        return this.isEnded() || this.isCancelled();
    }
    getHourDeparture() {
        return this.departure_datetime?.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
    }

    getHourArrival() {
        return this.arrival_datetime?.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
    }

    getHourUpdated() {
        return this.updated_at?.toLocaleDateString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
    }

    getUpdated() {
        return (
            this.created_at?.toLocaleDateString() +
            this.updated_at?.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
            })
        );
    }
    getCreated() {
        return (
            this.created_at?.toLocaleDateString() +
            this.created_at?.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
            })
        );
    }

    getDateDeparture() {
        return `${this.departure_datetime?.toLocaleDateString()}`;
    }

    getDateArrival() {
        return `${this.arrival_datetime?.toLocaleDateString()}`;
    }

    getTotalHt() {
        if (this.assignment && this.assignment.response) {
            const amountService = this.assignment?.response.price;
            const totalOptions =
                this.assignment?.response.options &&
                this.assignment?.response.options.length
                    ? this.assignment?.response.options
                          .map((option) => Number(option.price))
                          .reduce((acc, current) => acc + current)
                    : 0;
            return (
                (amountService ? amountService : 0) +
                (totalOptions ? totalOptions : 0)
            );
        }
        return 0;
    }

    getRateTva() {
        return this.type === TRANSFER_TYPE_STANDARD
            ? 10
            : this.type === TRANSFER_TYPE_PROVISION
            ? 20
            : 0;
    }

    getTotalTva() {
        return Number(this.getTotalCost() - this.getTotalHt());
    }

    getTotalCost() {
        if (this.assignment && this.assignment.response) {
            const amountService = this.assignment?.response.price * 1;
            const totalOptions =
                this.assignment?.response.options &&
                this.assignment?.response.options.length
                    ? this.assignment?.response.options
                          .map((option) => option.price * 1)
                          .reduce((acc, current) => acc + current)
                    : 0;

            return (
                (amountService ? amountService : 0) +
                (totalOptions ? totalOptions : 0)
            );
        }

        return 0;
    }

    canChoiceProvider(): boolean {
        return this.rights?.change_provider ? true : false;
    }

    canReassignDriver(): boolean {
        return (
            this.status === "accepted" ||
            this.status === "assigned" ||
            this.status === "to_validate" ||
            this.status === "pending" ||
            this.status === "validated"
        );
    }

    canShowClientOnTransfer(): boolean {
        return (
            this.status === "assigned" ||
            this.status === "to_validate" ||
            this.status === "validated" ||
            this.status === "ended"
        );
    }

    getTypeTender(): string {
        if (this.isTender()) {
            if (this.tender_price) {
                return "fixed_price";
            } else if (this.min_estimate) {
                return "standard";
            }
        }
        return "basic";
    }

    isTender(): boolean {
        return this.is_tender;
    }

    getColorTenderStatus(): string {
        let color = "transparent";
        switch (this.tender_status) {
            case "to_assign":
                color = "#00C6FB";
                break;
            case "accepted":
                color = "#ffa50099";
                break;
            case "refused":
                color = "#F36666";
                break;
            case "already_assigned":
                color = "#A6A9B7";
                break;
            case "validated":
                color = "#A1E89B";
                break;
        }

        return color;
    }

    getColorStatus(from: string): string {
        let color = "transparent";
        switch (from) {
            case "provider":
                switch (this.status) {
                    case "pending":
                        color = "rgb(26 188 176 / 78%)";
                        break;
                    case "refused":
                        color = "#FF6106";
                        break;
                    case "validated":
                        color = "#A1E89B";
                        break;
                    case "ended":
                        color = "rgb(112 112 112 / 82%)";
                        break;
                    case "cancelled":
                        color = "#F36666";
                        break;
                    case "running":
                        color = "#EEAF13";
                        break;
                    case "accepted":
                        color = "rgb(198 205 71 / 80%)";
                        break;
                    case "to_assign":
                        color = "#a6a4a4";
                        break;
                }
                break;
            case "admin":
                switch (this.status) {
                    case "to_assign":
                        color = "rgb(0, 195, 255)";
                        break;
                    case "assigned":
                        color = "#1ABCB0";
                        break;
                    case "pending":
                        color = "rgb(155 155 155 / 83%)";
                        break;
                    case "refused":
                        color = "#FF6106";
                        break;
                    case "accepted":
                        color = "#A1E89B";
                        break;
                    case "ended":
                        color = "#222222";
                        break;
                    case "cancelled":
                        color = "#a6a9b7";
                        break;
                    case "running":
                        color = "#a1e89b";
                        break;
                    case "validated":
                        color = "rgb(71, 241, 204)";
                        break;
                    case "in_place":
                        color = "#00c6fb";
                        break;
                    case "to_validate":
                        color = "#00A07D";
                        break;
                }
        }
        return color;
    }
}

export class TransferRights {
    public modify?: boolean;
    public delete?: boolean;
    public change_provider?: boolean;

    constructor(data: any = null) {
        this.modify = data?.modify;
        this.delete = data?.delete;
        this.change_provider = data?.change_provider;
    }
}

export class Passenger implements Deserializable {
    public id: number;
    public transfer_id: number;
    public civility: string;
    public lastname: string;
    public firstname: string;
    public phone: string;
    public email: string;
    public language: Language | null;
    public language_id: number;
    public current_time: Number;

    constructor(data: any = null) {
        this.id = data?.id;
        this.transfer_id = data?.transfer_id;
        this.civility = data?.civility;
        this.lastname = data?.lastname;
        this.firstname = data?.firstname;
        this.phone = data?.phone;
        this.email = data?.email;
        this.language_id = data?.language_id;
        this.language = data?.language ? new Language(data.language) : null;
        this.current_time =
            new Date().getTime() + Math.floor(Math.random() * Math.floor(9999));
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    getFullName(): string {
        return this.firstname + " " + this.lastname;
    }
}

export class RoutePoint implements Deserializable {
    public id: number;
    public transfer_id: number;
    public detail: string;
    public current_time: Number;

    constructor(data: any = null) {
        this.id = data?.id;
        this.transfer_id = data?.transfer_id;
        this.detail = data?.detail;
        this.current_time =
            new Date().getTime() + Math.floor(Math.random() * Math.floor(9999));
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}

export class Step implements Deserializable {
    public id: number;
    public driver_id: number;
    public status: string;
    public geo_lat: number;
    public geo_long: number;

    public created_at: Date;
    public updated_at: Date;

    constructor(data: any = null) {
        this.id = data?.id;
        this.driver_id = data?.driver_id;
        this.status = data?.status;
        this.geo_lat = data?.geo_lat;
        this.geo_long = data?.geo_long;

        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
