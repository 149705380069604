import { Deserializable } from "./interfaces/deserializable";
import { Client } from "./client";
import { Beneficiary } from "./beneficiary";
import { Transfer } from "./transfer";
import { Roles } from "./user";
import Colors from "../containers/design-dependencies/Colors";
import { Deposit } from "./payment";
import { User } from "./user";
import { Invoice } from "./invoice";
import { Address } from "./address";

export class Folder implements Deserializable {
    public id: number;
    public code: string;
    public name: string;
    public client_id?: number;
    public client?: Client | null;
    public start_date?: Date | null;
    public end_date?: Date | null;
    public status?: string;
    public created_at: Date;
    public updated_at: Date;
    public cancelled_at: Date | null;
    public referent_id?: number | null;
    public referent?: User | null;
    public transfers?: Transfer[];
    public extras: Extra[];
    public beneficiaries: Beneficiary[];
    public deposit?: Deposit | null;
    public invoice?: Invoice | null;
    public address?: Address | null;
    public billing_address?: string | null;
    public sms_detail?: string | null;

    public total_payments?: number;
    public total_deposit_payments?: number;
    public total_credits: number;
    public quote_id?: number;

    constructor(data: any) {
        this.id = data.id;
        this.code = data.code;
        this.name = data.name;
        this.status = data.status;
        this.client_id = data.client_id;
        this.client = data.client ? new Client(data.client) : null;

        this.start_date = data.start_date ? new Date(data.start_date) : null;
        this.end_date = data.start_date ? new Date(data.end_date) : null;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.cancelled_at = data.cancelled_at
            ? new Date(data.cancelled_at)
            : null;

        this.transfers = data.transfers
            ? data.transfers.map((transfer: any) => {
                  return new Transfer(transfer);
              })
            : [];

        this.extras = data.extras
            ? data.extras.map((extra: any) => {
                  return new Extra(extra);
              })
            : [];

        this.beneficiaries = data.beneficiaries
            ? data.beneficiaries.map((beneficiary: any) => {
                  return new Beneficiary(beneficiary);
              })
            : [];

        this.deposit = data.deposit ? new Deposit(data.deposit) : null;
        this.invoice = data.invoice ? new Invoice(data.invoice) : null;
        this.address = data.address ? new Address(data.address) : null;
        this.billing_address = data.billing_address
            ? data.billing_address
            : null;

        this.total_payments = data.total_payments;
        this.total_deposit_payments = data.total_deposit_payments;
        this.total_credits = data.total_credits;

        this.quote_id = data.quote_id;
        this.referent_id = data.referent_id;
        this.referent = data.referent ? new User(data.referent) : null;
        this.sms_detail = data.sms_detail;
    }

    getTotalTva() {
        let total = 0;
        this.transfers?.map((transfer: Transfer) => {
            total += transfer.getTotalTva();
        });
        return total;
    }

    getTotalHt() {
        return this.invoice ? this.invoice.price_excl_tax : null;
    }

    getTotalTTC() {
        return this.invoice ? this.invoice.price_incl_tax : null;
    }

    getPriceMargin() {
        return this.invoice ? this.invoice.price_margin : null;
    }

    getTransfer(id: number) {
        return this.transfers?.find((transfer: Transfer) => id === transfer.id);
    }

    getPriceTransfersHT() {
        let total = 0;
        this.transfers?.map((transfer: Transfer) => {
            total += transfer.getTotalHt();
        });
        return total;
    }

    getPriceTransfersTTC() {
        let total = 0;
        this.transfers?.map((transfer: Transfer) => {
            total += transfer.getTotalCost();
        });

        return total;
    }

    getColorStatus(role?: Roles) {
        if (role && role === Roles.CLIENT) {
            if (
                this.status === "draft" ||
                this.status === "preparing" ||
                this.status === "to_send"
            ) {
                return Colors.red;
            } else if (this.status === "sent") {
                return Colors.yellow;
            } else if (this.status === "confirmed") {
                return Colors.green;
            }
        } else {
            if (this.status === "draft") {
                return "#00C6FB";
            } else if (this.status === "preparing") {
                return "rgb(15 119 219 / 80%)";
            } else if (this.status === "to_send") {
                return "#F36666";
            } else if (this.status === "sent") {
                return "#1ABCB0";
            } else if (this.status === "confirmed") {
                return "#A1E89B";
            } else if (this.status === "refused") {
                return "#FF6106";
            } else if (this.status === "cancelled") {
                return "#2F2F2F";
            } else if (this.status === "in_progress") {
                return "rgb(255 183 37 / 80%)";
            } else if (this.status === "ended") {
                //TODO - Verifier nom status
                return "#707070";
            }
        }

        return "#ccc";
    }

    getStatus() {
        if (this.status === "to_go") {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (
                this.start_date &&
                this.start_date.getTime() <= today.getTime()
            ) {
                return "to_go_running";
            }
        }

        return this.status;
    }

    getLinkByStatus(role?: Roles) {
        if (role && role === Roles.CLIENT) {
            if (
                this.status === "draft" ||
                this.status === "preparing" ||
                this.status === "to_send"
            ) {
                return `/app/folder/${this.id}/edit`;
            } else {
                return `/app/folder/${this.id}/edit?tab=quote`;
            }
        } else {
            if (
                this.status === "draft" ||
                this.status === "preparing" ||
                this.status === "cancelled"
            ) {
                return `/app/folder/${this.id}/edit`;
            } else if (
                this.status === "to_send" ||
                this.status === "sent" ||
                this.status === "refused" ||
                this.status === "confirmed" ||
                this.status === "to_go"
            ) {
                return `/app/folder/${this.id}/edit?tab=quote`;
            } else if (this.status === "to_invoice") {
                return `/app/folder/${this.id}/edit?tab=invoice`;
            } else if (this.status === "ended") {
                return `/app/folder/${this.id}/edit?tab=payments`;
            }
        }

        return "";
    }

    getNumberPassengers() {
        if (this.transfers?.length) {
            return this.transfers
                ?.map((transfer) =>
                    transfer.nb_passenger ? Number(transfer.nb_passenger) : 0
                )
                .reduce((acc, current) => acc + current, 0);
        }
        return 0;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}

export class Extra implements Deserializable {
    public type: string;
    public price: number;
    public tva: number;
    public current_time: number;

    constructor(data: any = null) {
        this.type = data?.type;
        this.price = data?.price;
        this.tva = data?.tva;
        this.current_time =
            new Date().getTime() + Math.floor(Math.random() * Math.floor(9999));
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}

export class FolderBeneficiary implements Deserializable {
    public beneficiary_id: number;

    constructor(data: any = null) {
        this.beneficiary_id = data?.beneficiary_id;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
