import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorFormData } from "../../../../models/interfaces/errorForm";
import { ErrorForm } from "../../../../components/ErrorForm";
import { toastr } from "react-redux-toastr";
import BrandService from "../../../../services/brand-service";
import ImagePreview from "../../../../components/image-preview/ImagePreview";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import "./FormBrand.scss";
import FormTitle from "../../../../components/form-title/FormTitle";
import SubmitComponent from "../../../../components/submit-component/SubmitComponent";

interface IFormBrand {
    name: string;
    img_base64: any;
}

interface IFormProps {
    onAddSuccess: () => void;
}

const FormBrand: React.FunctionComponent<IFormProps> = ({ onAddSuccess }) => {
    const { register, handleSubmit, errors, setValue, control, reset } = useForm<
        IFormBrand
        >();
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState(false);
    const [imagePreview, setImagePreview] = useState<any>(null);
    const [isActivateButton,setIsActivateButton]=useState(true)
    const textAdd = t("add");

    const onSubmit = async (data: any, e: any) => {
        setIsActivateButton(false)
        setErrorApi(new ErrorFormData());
        const brandService = new BrandService();
        try {
            setDisabledForm(true);
            await brandService.add(data);
            onAddSuccess();
            toastr.success(t("success"), t("success_add_brand"));
            setDisabledForm(false);
            setImagePreview("");

            setValue("name", "");
            setValue("img_base64", "");

            reset();
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true)
    };

    return (
        <>
            <FormTitle title={t("brands_add")}/>
            <form className="form-react brands-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="field-form mgl2">
                    <InputControlled
                        name="name"
                        label={t("name")}
                        value=""
                        required={true}
                        disabled={disabledForm}
                        control={control}
                        errors={errors}
                    />
                </div>

                <div className="field-form mgl2 flexed-img">
                    <label>{t("image")}</label>
                    <div className="flex_row">
                        <input type="hidden" name="img_base64" ref={register()} />
                        <ImagePreview
                            disabledForm={disabledForm}
                            canUpdate={true}
                            size={"litle"}
                            onUpdateImage={(imgBase64) => setValue("img_base64", imgBase64)}
                        />
                    </div>
                </div>

                {imagePreview ? (
                    <div className="preview_image">
                        <img src={imagePreview} alt="" />
                    </div>
                ) : (
                    ""
                )}

                <SubmitComponent loading={false} text={textAdd} icon="add" mainClass="vehicle-details-submit" isActivate={isActivateButton}/>
                {errors.name && <p>{errors.name.message}</p>}
                <ErrorForm errorApi={errorApi} />
            </form>
        </>
    );
};

export default FormBrand;
