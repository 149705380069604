import React, { useState } from "react";
import { Provider } from "../../../models/provider";
import ImagePreview from "../../../components/image-preview/ImagePreview";
import {
    Button,
    Checkbox,
    CircularProgress,
    Switch,
    SwitchClassKey,
    SwitchProps,
} from "@material-ui/core";
import ActionTable from "../../../components/action-table/ActionTable";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import ProviderService from "../../../services/provider-service";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import BodyTableCell from "../../../components/table/BodyTableCell";
import BodyTableRow from "../../../components/table/BodyTableRow";
import ModalDelete from "../../../components/modal/ModalDelete";
import Modal from "../../../components/modal/Modal";
import ModalRedirectProvider from "../modal-provider/ModalRedirectProvider";

interface IItemProvider {
    provider: Provider;
    handleCheckbox: (e: any) => void;
    checkboxs: any;
    fetchKpis: () => void;
    setErrorApi: (error: ErrorFormData) => void;
    onDeleteSuccess: () => void;
}

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface Props extends SwitchProps {
    classes: Styles;
}

const ItemProvider = (
    {
        provider,
        handleCheckbox,
        checkboxs,
        fetchKpis,
        setErrorApi,
        onDeleteSuccess,
    }: IItemProvider,
    { classes, ...props }: Props
) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [redirectModalIsOpen, setRedirectModalIsOpen] = useState(false);
    const [tenderValue, setTenderValue] = useState<boolean>(
        provider.is_tender || false
    );
    const history = useHistory();
    const idProvider = provider.id.toString();
    const [switchLoader, setSwitchLoader] = useState(false);

    const editProvider = () => {
        history.push(`provider/${idProvider}`);
    };
    const openModal = () => {
        setIsOpen(true);
    };
    const deleteProvider = async (id: number) => {
        setIsOpen(false);
        try {
            const providerService = new ProviderService();
            await providerService.delete(id);
            onDeleteSuccess();
            toastr.success(t("success"), t("success_delete_provider"));
        } catch (e) {
            toastr.error(t("error"), t("unexpected_error"));
        }
    };
    //Handle change tender switch provider
    const changeTenderProvider = async (tender: boolean) => {
        setSwitchLoader(true);
        try {
            const providerService = new ProviderService();

            await providerService.patch(
                {
                    is_tender: tender,
                },
                provider.id
            );
            toastr.success(t("success"), t("success_save_provider"));
            setTenderValue(tender);
            fetchKpis();
        } catch (error) {
            setTenderValue(!tender);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setSwitchLoader(false);
    };

    return (
        <BodyTableRow>
            <BodyTableCell align="center">
                <Checkbox
                    id={idProvider}
                    checked={
                        checkboxs.selections.includes(idProvider) ? true : false
                    }
                    onChange={(e) => handleCheckbox(e)}
                />
            </BodyTableCell>

            <BodyTableCell>
                <div className="upload-image-table">
                    <ImagePreview
                        size="litle"
                        existingImageUrl={provider?.img_url}
                        onUpdateImage={() => null}
                    />
                </div>
            </BodyTableCell>

            <BodyTableCell align="center">
                <div
                    className="link-table"
                    onClick={() => history.push(`provider/${provider.id}`)}
                >
                    {provider.company_name}
                </div>
            </BodyTableCell>
            <BodyTableCell align="center">
                {provider.user?.getFullName()}
            </BodyTableCell>
            <BodyTableCell align="center">{provider.email}</BodyTableCell>
            <BodyTableCell align="center">
                {provider.address?.city}
            </BodyTableCell>
            <BodyTableCell align="center">
                {provider.phone ? `+${provider.phone}` : "-"}
            </BodyTableCell>
            <BodyTableCell align="center">{provider.nb_vehicles}</BodyTableCell>
            <BodyTableCell align="center">{provider.nb_drivers}</BodyTableCell>
            <BodyTableCell align="center">
                {provider.provider_type?.label &&
                    t(
                        "form." +
                            provider.provider_type?.label
                                .charAt(0)
                                .toLowerCase() +
                            provider.provider_type?.label.slice(1)
                    )}
            </BodyTableCell>
            <BodyTableCell align="center">
                {!switchLoader ? (
                    <Switch
                        onChange={(e) => changeTenderProvider(e.target.checked)}
                        checked={!!tenderValue}
                        name={idProvider}
                        disableRipple
                        className="switch-ios"
                        {...props}
                    />
                ) : (
                    <CircularProgress />
                )}
            </BodyTableCell>
            <BodyTableCell align="center">
                <ReactSVG
                    style={{ cursor: "pointer" }}
                    src="/images/app_icons/clock-circular-outline.svg"
                    onClick={() =>
                        history.push(`provider/${provider.id}/transfers`)
                    }
                />
            </BodyTableCell>
            <BodyTableCell align="center" className="provider-actions-row">
                <ActionTable
                    {...(!provider.canBeDeleted()) || provider.nb_drivers !== 0 ||
                          provider.nb_vehicles !== 0
                            ? {
                                  deleteAction: () =>
                                      setRedirectModalIsOpen(true),
                              }
                            : { deleteAction: () => openModal() }
                    }   
                    updateAction={() => editProvider()}
                />
                <ModalDelete
                    isOpen={isOpen}
                    closeDialog={() => setIsOpen(false)}
                    itemType="provider"
                    itemCode={provider.id.toString()}
                    itemName={provider.company_name}
                    handleDelete={() => {
                        deleteProvider(provider.id);
                    }}
                    isDeleteAction
                />
                <ModalRedirectProvider
                    isOpen={redirectModalIsOpen}
                    closeDialog={() => setRedirectModalIsOpen(false)}
                    provider={provider}
                />
            </BodyTableCell>
        </BodyTableRow>
    );
};

export default ItemProvider;
