import React, { useState, useEffect } from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TransferService from "../../services/transfer-service";
import { Transfer } from "../../models/transfer";
import FormTransferAmount from "./form-transfer-amount/FormTransferAmount";
import { CircularProgress } from "@material-ui/core";
import ProviderService from "../../services/provider-service";
import { Driver } from "../../models/driver";
import { connect } from "react-redux";
import { User } from "../../models/user";
import { ErrorFormData } from "../../models/interfaces/errorForm";
import { ErrorForm } from "../../components/ErrorForm";
import { Assignment } from "../../models/assignment";

interface IPropsAmountPage {
    user: User;
}

const TransferAmountFormPage = ({ user }: IPropsAmountPage) => {
    const { t } = useTranslation();
    const { id, idAssignment } = useParams();
    const [loading, setLoading] = useState(true);
    const [transfer, setTransfer] = useState<Transfer>();
    const [assignment, setAssignment] = useState<Assignment>();
    const [drivers, setDrivers] = useState<Driver[]>([]);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());

    useEffect(() => {
        const fetchTransfers = async () => {
            if (id) {
                try {
                    setLoading(true);
                    const transferService = new TransferService();
                    let trans = await transferService.get(id);

                    setTransfer(trans);
                    const providerService = new ProviderService();
                    if (user?.id && user?.provider) {
                        setDrivers(await providerService.getDriversTenders(id));
                    }
                    if (trans.assignments && idAssignment) {
                        setAssignment(
                            trans.assignments.filter(
                                (assign: Assignment) =>
                                    assign.id === Number(idAssignment)
                            )[0]
                        );
                        // console.log(trans);
                    }
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    setErrorApi(new ErrorFormData());
                }
            }
        };
        fetchTransfers();
    }, [id, user, idAssignment]);

    return (
        <ContentSection
            title={`${t("transfer")}: ${transfer ? transfer.code : ""} ${
                transfer?.folder ? transfer.folder.code : ""
            }`}
            returnAction={true}
        >
            {transfer && !loading ? (
                <FormTransferAmount
                    transfer={transfer}
                    drivers={drivers}
                    assignment={assignment}
                />
            ) : (
                <CircularProgress />
            )}
            <ErrorForm errorApi={errorApi} />
        </ContentSection>
    );
};
function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(TransferAmountFormPage);
