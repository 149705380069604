import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Transfer } from "../../../models/transfer";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import { ReactSVG } from "react-svg";
import InfoClient from "../../../components/popups/info-client/InfoClient";
import { Button } from "@material-ui/core";

interface IItem {
    transfer: Transfer;
}

const ItemTracking = ({transfer} : IItem) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [showClient, setShowClient] = useState(false);

    return (
        <BodyTableRow>
            <BodyTableCell align="center">
                {transfer.folder?.code} <br />
                {transfer.code}
            </BodyTableCell>
            <BodyTableCell align="center">
                {transfer.assignment?.response?.driver?.getFullName()}
            </BodyTableCell>
            <BodyTableCell align="center" className="client_cell">
                <ReactSVG
                    src="/images/app_icons/user.svg"
                    className="svg_client"
                    onMouseEnter={() => setShowClient(true)}
                    onMouseLeave={() => setShowClient(false)}
                />
                {showClient && transfer?.folder && (
                    <InfoClient client={transfer.folder?.client} />
                )}
            </BodyTableCell>
            <BodyTableCell align="center">{transfer.nb_passenger}</BodyTableCell>
            <BodyTableCell align="center">
                {transfer.departure_datetime?.toLocaleDateString()}
            </BodyTableCell>
            <BodyTableCell align="center">
                {transfer.getHourDeparture()}
            </BodyTableCell>
            <BodyTableCell align="center">
                {transfer.departure_location}
            </BodyTableCell>
            <BodyTableCell align="center">
                <Button
                    className="btn-rounded mr-2"
                    variant="contained"
                    onClick={() =>
                        history.push(`trackings/${transfer.id}`)
                    }
                >
                    {t("view")}
                </Button>
            </BodyTableCell>
        </BodyTableRow>
    )
};

export default ItemTracking;
