import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import FormTitle from "../../../components/form-title/FormTitle";
import SubmitComponent from "../../../components/submit-component/SubmitComponent";
import "./ClientGrants.scss";
import { Client, Grant, Grants } from "../../../models/client";

import { User } from "../../../models/user";
import { useForm } from "react-hook-form";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { ErrorForm } from "../../../components/ErrorForm";
import InputControlled from "../../../components/fields-controlled/InputControlled";
import ClientGrantService from "../../../services/client-grant-service";
import ModalDelete from "../../../components/modal/ModalDelete";

interface IProps {
    client: Client;
    me: User;
}

interface IFormGrant {
    email: string;
    level: string;
}

export default function ClientGrants({ client, me }: IProps) {
    const { t } = useTranslation();

    const { register, handleSubmit, errors, setValue, control, reset } = useForm<IFormGrant>();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [grants, setGrants] = useState<Grant[]>([]);
    const [isOpen, setIsOpen] = useState(false);


    React.useEffect(() => {
        register("level"); // custom register input
        setValue("level", Grants.READER); // set default value
    }, [register, setValue]);

    useEffect(() => {
        const getGrants = async () => {
            try {
                const service = new ClientGrantService(client.id);
                setGrants(await service.all());
            } catch (error) {
                console.log("error", error);
            }
            setLoaded(true);
        };
        getGrants();
        
    }, [client]);

    
    const addGrant = async (data: any, e: any) => {
        setErrorApi(new ErrorFormData());
        setDisabledForm(true);
        try {

            const service = new ClientGrantService(client.id);
            let grant = await service.add(data);

            toastr.success(t("success"), t("success_update_client"));
            setGrants([...grants, grant]);

            reset();

        } catch (error) {
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setDisabledForm(false);
    };

    const updateGrant = async (id: number, level: string) => {
        setErrorApi(new ErrorFormData());

        const service = new ClientGrantService(client.id);
        await service.update(id, {level: level});

        toastr.success(t("success"), t("success_update_client"));
    };

    const removeGrant = async (id: number) => {
        setErrorApi(new ErrorFormData());

        const service = new ClientGrantService(client.id);
        await service.delete(id);

        toastr.success(t("success"), t("success_update_client"));
        setGrants(grants.filter((grant: Grant) => (grant.id !== id)));
        setIsOpen(false);
    };

    function canChange(grant: Grant): boolean{
        if (grant.user_id === me.id) {
            //cannot change my grant
            return false;
        }
        if (me.isClient() && !me.isOwner(client)) {
            return false;
        }
        return true;
    }

    function renderAddForm() {
        return (
            <>
                <FormTitle title={""}/>
                <form
                    className="form-react grant-add-form"
                    onSubmit={handleSubmit(addGrant)}
                >
                    
                    <div className="provider-form-part">
                        <div className="field-form mgl4">
                            <InputControlled
                                name="email"
                                label={t("form.email")}
                                value=""
                                required={true}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                            />
                        </div>
                        <div className="field-form mgl4">
                            <FormControl className="mgl2">
                                <InputLabel>{t('grants.rights')}</InputLabel>
                                <Select
                                    name="level"
                                    onChange={(e:any) => {setValue('level', e.target.value)}}
                                    defaultValue={Grants.READER}
                                    className="grant-selector"
                                >
                                    <MenuItem value={Grants.OWNER}>{t('grants.grant.'+Grants.OWNER)}</MenuItem>
                                    <MenuItem value={Grants.MANAGER}>{t('grants.grant.'+Grants.MANAGER)}</MenuItem>
                                    <MenuItem  value={Grants.READER}>{t('grants.grant.'+Grants.READER)}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="field-form mgl4">
                            <SubmitComponent loading={false} text={t("add")} icon="add" mainClass="grant-submit" isActivate={true}/>
                        </div>
                    </div>
                    {errors.email && <p>{errors.email.message}</p>}
                    <ErrorForm errorApi={errorApi} />
                </form>
            </>
        )
    }

    function renderGrantFields (grant: Grant) {
        return (
            <>
                <div className="provider-form-part">
                    <div className="field-form mgl4">
                        <TextField
                            disabled={true}
                            label={t("form.email")}
                            value={grant.user?.email}
                        />
                    </div>
                    <div className="field-form mgl4">
                        <FormControl className="mgl2">
                            <InputLabel>{t('grants.rights')}</InputLabel>
                            <Select
                                name="brand_id"
                                onChange={(e:any) => {updateGrant(grant.id, e.target.value)}}
                                defaultValue={grant.level}
                                disabled={!canChange(grant)}
                                className="grant-selector"
                            >
                                <MenuItem value={Grants.OWNER}>{t('grants.grant.'+Grants.OWNER)}</MenuItem>
                                <MenuItem value={Grants.MANAGER}>{t('grants.grant.'+Grants.MANAGER)}</MenuItem>
                                <MenuItem value={Grants.READER}>{t('grants.grant.'+Grants.READER)}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="field-form mgl4 delete">
                        {canChange(grant)
                            && <Delete style={{cursor: 'pointer'}} onClick={() => {setIsOpen(true)}} />
                        }
                        <ModalDelete
                            isOpen={isOpen}
                            closeDialog={() => setIsOpen(false)}
                            itemType="client"
                            itemCode={grant.user?.email ? grant.user?.email : ''}
                            handleDelete={() => removeGrant(grant.id)}
                            isDeleteAction
                        />
                    </div>
                </div>
            </>
        )
    }

    return !loaded ? (<div>{t("loading")}</div>) : (
        <>
            <div className="multi">
                <div className="multi-container">
                    {grants.map(
                        (grant: Grant, index: number) => (
                            <div
                                key={"grant-" +index +"id-" + grant.id}
                                className="grant"
                            >
                                {renderGrantFields(grant)}
                            </div>
                        )
                    )}
                    <div>
                    {!grants.length ? (<div>{t("no_items")}</div>) : ''}
                    </div>
                </div>
            </div>
            {
                me.isAdmin() || me.isOwner(client)
                ? renderAddForm()
                : ''
            }
        </>
    );
}

