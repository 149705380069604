import React, {useState} from "react";
import IconEdit from "@material-ui/icons/Edit";
import IconClear from "@material-ui/icons/Delete";
import IconCheck from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {ErrorFormData} from "../../../../models/interfaces/errorForm";
import {toastr} from "react-redux-toastr";
import {ErrorForm} from "../../../../components/ErrorForm";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import {VehicleColor} from "../../../../models/vehicle-color";
import VehicleColorService from "../../../../services/vehicle-color-service";
import ImagePreview from "../../../../components/image-preview/ImagePreview";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import './ItemColors.scss';

interface IItemColor {
    vehicleColor: VehicleColor;
    onUpdateSuccess: (vehicleColor: VehicleColor) => void;
    onDeleteSuccess: () => void;
}

interface IFormColor {
    color: string;
    type: boolean;
    hex_color: string
    img_base64: any;
}

const ItemColor: React.FunctionComponent<IItemColor> = ({vehicleColor, onUpdateSuccess, onDeleteSuccess,}) => {
    const [editing, setIsEditing] = useState<boolean>(false);
    const [disabledForm, setDisabledForm] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        control,
        reset,
    } = useForm<IFormColor>();
    const {t} = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const onSubmit = async (data: any, e: any) => {
        setErrorApi(new ErrorFormData());
        const vehicleColorService = new VehicleColorService();
        try {
            setDisabledForm(true);
            const editedVehicleColor = await vehicleColorService.update(
                data,
                vehicleColor.id
            );
            onUpdateSuccess(editedVehicleColor);
            toastr.success(t("success"), t("success_updated_color"));
            setDisabledForm(false);
            cancelEditing();
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    const deleteColor = async () => {
        setErrorApi(new ErrorFormData());
        const vehicleColorService = new VehicleColorService();
        try {
            await vehicleColorService.delete(vehicleColor.id);
            onDeleteSuccess();
            toastr.success(t("success"), t("success_deleted_color"));
            cancelEditing();
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    const cancelEditing = () => {
        setIsEditing(false);
        setValue("img_base64", null);
        reset();
    };

    return (
        <>
            {!editing ? (
                <>
                    <td>{vehicleColor.color}</td>
                    <td>{vehicleColor.type === 0 ? t('interior') : t('exterior')}</td>
                    <td align={"center"}>
                        {vehicleColor.img_url ? (
                            <ImagePreview
                                size="little"
                                existingImageUrl={vehicleColor.img_url}
                                onUpdateImage={() => null}
                            />
                        ) : ("")}
                    </td>
                    <td>{vehicleColor.hex_color}</td>
                    <td className="actions">
                        <IconClear onClick={() => deleteColor()} className="delete-action"/>
                        <IconEdit onClick={() => setIsEditing(true)} className="edit-action"/>
                        <ErrorForm errorApi={errorApi}/>
                    </td>
                </>
            ) : (
                <>
                    <td className="actions" colSpan={3}>
                        <form
                            className="form_row align_items_center"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <InputControlled
                                name="color"
                                className="edit-item-color mgr1"
                                label={t("color")}
                                value={vehicleColor.color}
                                required={true}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                            />
                            <FormControl className="edit-item-color mgr1">
                                <InputLabel>{t('type')}</InputLabel>
                                <Controller
                                    as={
                                        <Select>
                                            <MenuItem value={0}>{t('interior')}</MenuItem>
                                            <MenuItem value={1}>{t('exterior')}</MenuItem>
                                        </Select>
                                    }
                                    name="type"
                                    defaultValue="0"
                                    className="color-type-selector"
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                />
                            </FormControl>
                            <input type="hidden" name="img_base64" ref={register()}/>
                            <ImagePreview
                                disabledForm={disabledForm}
                                size="little"
                                existingImageUrl={vehicleColor.img_url}
                                canUpdate={true}
                                onUpdateImage={(imgBase64) => setValue("img_base64", imgBase64)}
                            />
                            <InputControlled
                                name="hex_color"
                                className="edit-item-color mgr1"
                                label={t("hex_color")}
                                value={vehicleColor.hex_color}
                                required={true}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                            />
                            <ErrorForm errorApi={errorApi}/>

                            <button className="submit_icon" type="submit">
                                <IconCheck/>
                            </button>
                            <CloseIcon onClick={() => cancelEditing()} className="cancel-edit"/>
                        </form>
                    </td>
                </>
            )}
        </>
    );
};

export default ItemColor;
