import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import ImagePreview from "../../../components/image-preview/ImagePreview";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import SubmitComponent from "../../../components/submit-component/SubmitComponent";
import { ErrorForm } from "../../../components/ErrorForm";
import InputControlled from "../../../components/fields-controlled/InputControlled";
import FormSection from "../../../components/form-section/FormSection";
import { Driver } from "../../../models/driver";
import DriverService from "../../../services/driver-service";
import "./FormDriver.scss";
import MultiSelectControlled from "../../../components/fields-controlled/MultiSelectControlled";
import { useParams } from "react-router-dom";
import CountryPhoneControlled from "../../../components/fields-controlled/CountryPhoneControlled";
import { connect } from "react-redux";
import { User } from "../../../models/user";
import { Language } from "../../../models/language";

interface PropsFormDriver {
    driver?: Driver;
    languages: Array<Language>;
    user: User;
}

interface FormProps {
    img_profil_key: string;
    img_profil_url: string;
    img_card_key: string;
    img_card_url: string;
    last_name: string;
    first_name: string;
    phone: string;
    email: string;
    notes: string;
    is_tender: boolean;
    languages: Array<Language>;
    status: string;
    token_activation: string;
    token_resetting_password: string;
}

function FormDriver({ driver, languages, user }: PropsFormDriver) {
    const { t } = useTranslation();
    const { register, handleSubmit, errors, setValue, control } =
        useForm<FormProps>();
    const { id } = useParams();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState(false);
    const history = useHistory();
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [switchs, setSwitchs] = useState<any>(
        driver ? !!driver?.is_tender : false
    );
    const [languagesSelected, setLanguagesSelected] = useState<any[]>([]);
    const [isActivateButton, setIsActivateButton] = useState(true);
    // console.log(driver && !!driver.is_tender);
    useEffect(() => {
        register("language_ids");
        if (driver?.languages) {
            setLanguagesSelected(
                driver.languages.map((lang) => {
                    return {
                        label: lang.label,
                        value: lang.id,
                    };
                })
            );
            setValue(
                "language_ids",
                driver.languages.map((lang) => {
                    return lang.id;
                })
            );
        }
    }, [driver, register, setValue]);
    const onSubmit = async (data: any) => {
        setIsActivateButton(false);
        const driverService = new DriverService();
        try {
            setLoadingSubmit(true);
            setDisabledForm(true);
            if (driver) {
                await driverService.put(data, id);
                toastr.success(t("success"), t("success_save_driver"));
                history.push("/app/drivers");
            } else {
                let provider_id = user.provider ? user.provider.id : id;

                await driverService.post(data, provider_id);
                toastr.success(t("success"), t("success_add_driver"));
                history.push("/app/drivers");
            }
        } catch (e) {
            console.log(e);
            setDisabledForm(false);
            setLoadingSubmit(false);
            setErrorApi(new ErrorFormData(e.response.data));
        }
        setIsActivateButton(true);
    };
    const onChangeSwitchs = (event: any) => {
        setSwitchs(event.currentTarget.checked);
    };
    const onChangeSelected = (event: any) => {
        setLanguagesSelected(event);
        setValue(
            "language_ids",
            event.map((e: any) => {
                return e.value;
            })
        );
    };

    return (
        <>
            <form
                className="form-react-benef"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormSection title={t("form.identity")} className="mgl2">
                    <div className="row row-md mt-3">
                        <div className="col">
                            <label className="bold-label">{t("picture")}</label>
                            <div className="flex_row">
                                <input
                                    type="hidden"
                                    name="img_base64"
                                    ref={register()}
                                />
                                <ImagePreview
                                    disabledForm={disabledForm}
                                    canUpdate={true}
                                    existingImageUrl={driver?.img_profil_url}
                                    onUpdateImage={(imgBase64) => {
                                        setValue("img_base64", imgBase64);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg">
                            <div className="form-group">
                                <InputControlled
                                    name="last_name"
                                    label={`${t("form.driver_last_name")} *`}
                                    value={driver ? driver.last_name : ""}
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col-lg">
                            <div className="form-group">
                                <InputControlled
                                    name="first_name"
                                    label={`${t("form.driver_first_name")} *`}
                                    value={driver ? driver.first_name : ""}
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                    </div>
                </FormSection>
                <FormSection title={t("form.contact")} className="mgl2">
                    <div className="row row-md mt-3">
                        {/* <div className="field-form col-12 col-lg-4 col-xl-2">
                            <InputControlled
                                name="phone"
                                label={t("form.phone")}
                                value={driver ? driver.phone : ""}
                                required={true}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                            />
                        </div> */}
                        <div className="col-lg">
                            <div className="form-group has-phone-input-as-input-height">
                                <CountryPhoneControlled
                                    name={"phone"}
                                    label={`${t("form.phone")} *`}
                                    value={driver?.phone ? driver.phone : ""}
                                    disabled={disabledForm}
                                    errors={errors}
                                    required={true}
                                    control={control}
                                />
                            </div>
                        </div>
                        <div className="col-lg">
                            <div className="form-group">
                                {driver && <div className="simple-input-display">
                                    <strong>{`${t("form.email")}`} : </strong>
                                    <span>{driver ? driver.email : ""}</span>
                                </div> }

                                {!driver && <InputControlled
                                    name="email"
                                    label={`${t("form.email")} *`}
                                    value={""}
                                    required={true}
                                    disabled={driver ? true : disabledForm}
                                    control={control}
                                    errors={errors}
                                /> }
                            </div>
                        </div>
                    </div>
                </FormSection>
                <FormSection
                    title={t("form.miscellaneous")}
                    className="mgl2 divers"
                >
                    <div className="row row-md mt-3">
                        <div className="col-lg">
                            <div className="langue-section form-group">
                                <MultiSelectControlled
                                    list={languages}
                                    errors={errors}
                                    name="languages"
                                    label={t("language")}
                                    selected={languagesSelected}
                                    onChange={(event: any) =>
                                        onChangeSelected(event)
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-lg">
                            <div className="form-group">
                                <FormControlLabel
                                    className="switch-label-top"
                                    control={
                                        <Switch
                                            inputRef={register}
                                            name="is_tender"
                                            className="switch-ios"
                                            onChange={onChangeSwitchs}
                                            checked={switchs}
                                        />
                                    }
                                    label={t("form.is_tender")}
                                    labelPlacement="top"
                                />
                            </div>
                        </div>
                        <div className="col-lg pro-card-container">
                            <div className="form-group">
                                <label className="bold-label">
                                    {t("form.pro_card")}
                                </label>
                                <div className="pro-card">
                                    <input
                                        type="hidden"
                                        name="img_card"
                                        ref={register()}
                                    />
                                    <ImagePreview
                                        disabledForm={disabledForm}
                                        canUpdate={true}
                                        defaultImage={null}
                                        existingImageUrl={driver?.img_card_url}
                                        onUpdateImage={(img_card) => {
                                            setValue("img_card", img_card);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md">
                            <div className="form-group">
                                <InputControlled
                                    name="notes"
                                    label={t("note")}
                                    value={driver?.notes ? driver?.notes : ""}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                    multiline={true}
                                />
                            </div>
                        </div>
                    </div>
                </FormSection>
                <SubmitComponent
                    loading={loadingSubmit}
                    text={t("form.validate")}
                    isActivate={isActivateButton}
                />

                <ErrorForm errorApi={errorApi} />
            </form>
        </>
    );
}

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(FormDriver);
