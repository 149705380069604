import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionTable from "../../../components/action-table/ActionTable";
import { Link, useHistory } from "react-router-dom";
import { Transfer } from "../../../models/transfer";
import TransferService from "../../../services/transfer-service";
import { User } from "../../../models/user";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import { ReactSVG } from "react-svg";
import InfoClient from "../../../components/popups/info-client/InfoClient";
import ModalUnassignment from "../../transfer/list-transfers/modal-unassignment/ModalUnassignment";

import { Assignment } from "../../../models/assignment";
import TransferNote from "../../../components/popups/transfer-note/TransferNote";

interface IItemTender {
    transfer: Transfer;
    onDeleteSuccess: () => void;
    user: User;
}

const ItemTender = ({ transfer, onDeleteSuccess, user }: IItemTender) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [showClient, setShowClient] = useState(false);
    const [showUnasign, setShowUnassign] = useState<boolean>(false);
    const [assignment, setAssignment] = useState<Assignment>();
    const [showNote, setShowNote] = useState(false);

    useEffect(() => {
        const service = new TransferService();
        if (user.isProvider() && transfer.tender_status !== "to_assign") {
            const getResponse = async () => {
                const assign = await service.assignments(transfer.id);
                setAssignment(assign[0]);
            };
            getResponse();
        }
    }, [user, transfer]);
    return (
        <BodyTableRow>
            <BodyTableCell align="center">
                <div
                    className="link-table"
                    onClick={() =>
                        history.push(`transfer/${transfer.id}/amount`)
                    }
                >
                    {transfer.folder?.code} <br />
                    {transfer.code}
                </div>
            </BodyTableCell>
            <BodyTableCell align="center" className="client_cell">
                <ReactSVG
                    src="/images/app_icons/user.svg"
                    className="svg_client"
                    onMouseEnter={() => setShowClient(true)}
                    onMouseLeave={() => setShowClient(false)}
                />
                {showClient && transfer?.folder && (
                    <InfoClient client={transfer.folder?.client} />
                )}
            </BodyTableCell>
            <BodyTableCell align="center">
                {transfer.nb_passenger}
            </BodyTableCell>
            <BodyTableCell align="center">
                {transfer.departure_datetime?.toLocaleDateString()}
            </BodyTableCell>
            <BodyTableCell align="center">
                {transfer.getHourDeparture()}
            </BodyTableCell>
            <BodyTableCell align="center">
                {transfer.departure_location}
            </BodyTableCell>
            <BodyTableCell align="center">{transfer.infos}</BodyTableCell>
            <BodyTableCell align="center">
                {transfer.arrival_location}
            </BodyTableCell>
            <BodyTableCell align="center">
                <ReactSVG
                    src="/images/app_icons/chat.svg"
                    onMouseEnter={() => setShowNote(true)}
                    onMouseLeave={() => setShowNote(false)}
                />
                {showNote && <TransferNote transfer={transfer} />}
            </BodyTableCell>
            <BodyTableCell align="center" className="tag-status">
                <div className="tag-table-section">
                    <div className="tag-table-content">
                        {transfer.tender_status === "to_assign" ? (
                            <Link
                                className="tag-table"
                                style={{
                                    backgroundColor:
                                        transfer.getColorTenderStatus(),
                                }}
                                to={`/app/transfer/${transfer.id}/amount`}
                            >
                                <span className="to-upper">
                                    {t(
                                        "tender_status." +
                                            transfer.tender_status
                                    )}
                                </span>
                            </Link>
                        ) : (
                            <div
                                className="tag-table"
                                style={{
                                    backgroundColor:
                                        transfer.getColorTenderStatus(),
                                }}
                            >
                                <span className="to-upper">
                                    {t(
                                        "tender_status." +
                                            transfer.tender_status
                                    )}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </BodyTableCell>

            <BodyTableCell align="center">
                {transfer.min_estimate
                    ? `${transfer.min_estimate} - ${transfer.max_estimate} ${t(
                          "money_symbol"
                      )}`
                    : `${transfer.tender_price} ${t("money_symbol")}`}
            </BodyTableCell>
            <BodyTableCell align="center">
                {assignment?.response &&
                    assignment?.response.price &&
                    `${assignment?.response.price} ${t("money_symbol")}`}
            </BodyTableCell>
            <BodyTableCell align="center">
                {assignment?.response &&
                    assignment?.response.driver &&
                    assignment?.response.driver.getFullName()}
            </BodyTableCell>

            <BodyTableCell align="right" className="tenders-list">
                {(!assignment && transfer.tender_status !== "to_assign") ||
                    (transfer.tender_status !== "already_assigned" &&
                        assignment && (
                            <>
                                {transfer.tender_status === "refused" ? (
                                    <ActionTable
                                        updateAction={() =>
                                            history.push({
                                                pathname: `/app/transfer/${transfer.id}/amount/${assignment.id}`,
                                            })
                                        }
                                    />
                                ) : (
                                    <ActionTable
                                        deleteAction={() =>
                                            setShowUnassign(true)
                                        }
                                        updateAction={() =>
                                            history.push({
                                                pathname: `/app/transfer/${transfer.id}/amount/${assignment.id}`,
                                            })
                                        }
                                    />
                                )}
                            </>
                        ))}
            </BodyTableCell>
            {user.provider && (
                <ModalUnassignment
                    isOpen={showUnasign}
                    transfer={transfer}
                    closeDialog={() => setShowUnassign(false)}
                    providerId={user.provider.id}
                    onRefresh={() => {
                        onDeleteSuccess();
                    }}
                />
            )}
        </BodyTableRow>
    );
};

export default ItemTender;
