import React from "react";
import "./InfoClient.scss";
import { useTranslation } from "react-i18next";
import { Client } from "../../../models/client";

interface IPopupClient {
    client?: Client | null;
}

const InfoClient = ({ client }: IPopupClient) => {
    const { t } = useTranslation();
    return client ? (
        <div className="infos_client_popup">
            <div className="top-info section">
                <div className="info_part">
                    <span className="label">{t("form.client")}</span>
                    <span>{client.company_name}</span>
                </div>
                <div className="info_part">
                    <span className="label">{t("form.civility")}</span>
                    <span>{client.user?.gender}</span>
                </div>
            </div>
            <div className="bottom-info section">
                <div className="info_part">
                    <span className="label">{t("firstname")}</span>
                    <span>{client.user?.firstname}</span>
                </div>
                <div className="info_part last">
                    <span className="label">{t("name")}</span>
                    <span>{client.user?.lastname}</span>
                </div>
            </div>
        </div>
    ) : (
        <div className="no_infos_client_popup">{t("client_not_found")}</div>
    );
};

export default InfoClient;
