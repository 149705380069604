import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import BillingSvg from "../menu-icons/BillingSvg";

export function LeftSideNavAdministrator() {
    const { t } = useTranslation();
    let { url } = useRouteMatch();

    return (
        <>
            <List className="asm-list asml-top">
                <SubNavProvider url={url} t={t} />

                <SubNavClient url={url} t={t} />

                <SubNavFolder url={url} t={t} />
              
                <Link className="side-nav-link" to={`${url}/quotes`}>
                  <ListItem button>
                    <ListItemIcon>
                        <img
                            src={require("../menu-icons/devis.svg")}
                            alt="Devis & facturation"
                        />
                    </ListItemIcon>
                    <ListItemText primary={t("nav.estimate")}/>
                  </ListItem>
                </Link>
                
                <Link className="side-nav-link" to={`${url}/invoices`}>
                  <ListItem button>
                    <ListItemIcon>
                        <BillingSvg />
                    </ListItemIcon>
                    <ListItemText primary={t("nav.invoices")}/>
                  </ListItem>
                </Link>

                <Link className="side-nav-link" to={`${url}/credits`}>
                  <ListItem button>
                    <ListItemIcon>
                        <BillingSvg />
                    </ListItemIcon>
                    <ListItemText primary={ t("nav.credits")}/>
                  </ListItem>
                </Link>

                <Link className="side-nav-link" to={`${url}/support`}>
                  <ListItem button>
                    <ListItemIcon>
                        <img
                            src={require("../menu-icons/help.svg")}
                            alt="Support"
                        />
                    </ListItemIcon>
                    <ListItemText primary={t("nav.support") }/>
                  </ListItem>
                </Link>

                {/* <Link className="side-nav-link" to={`${url}/coordinators`} >
                  <ListItem button>
                    <ListItemIcon>
                   
                        <img
                            src={require("../menu-icons/person.svg")}
                            alt="Commanditaires"
                        />
                    </ListItemIcon>
                    <ListItemText primary={"Commanditaires"}/>
                  </ListItem>
                </Link> */}
            </List>
        </>
    );
}

function SubNavProvider({ url, t }: any) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <Link className="side-nav-link" to={`${url}/providers`}>
              <ListItem button onClick={() => setIsOpen(!isOpen)}>
                 <ListItemIcon>
                    <img
                        src={require("../menu-icons/prestataires.svg")}
                        alt="prestataires"
                    />
                 </ListItemIcon>
                 <ListItemText primary={t("nav.providers")}/>
                 {isOpen ?  <ExpandLess />: 
                           <Link to={`${url}/providers`}>
                                <ExpandMore/>
                           </Link> 
                 }
              </ListItem>
            </Link>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                  <Link className="side-nav-link" to={`${url}/vehicles`} >
                    <ListItem button>
                        <ListItemIcon>
                            <img
                                src={require("../menu-icons/car.svg")}
                                alt="Véhicules"
                            />
                        </ListItemIcon>
                        <ListItemText primary={t("nav.vehicles")}/>
                    </ListItem>
                   </Link>
                    
                   <Link className="side-nav-link" to={`${url}/drivers`} >
                    <ListItem button>
                        <ListItemIcon>
                          <Link className="side-nav-link" to={`${url}/drivers`} >
                            <img
                                src={require("../menu-icons/bxs-user-pin.svg")}
                                alt="les chauffeurs"
                            />
                          </Link>
                        </ListItemIcon>
                        <ListItemText primary={t("nav.drivers")}/>
                    </ListItem>
                   </Link>

                </List>
            </Collapse>
        </>
    );
}

function SubNavClient({ url, t }: any) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>

           <Link className="side-nav-link" to={`${url}/clients`}>  
            <ListItem button onClick={() => setIsOpen(!isOpen)}>
                <ListItemIcon>
                    <img
                        src={require("../menu-icons/users.svg")}
                        alt="Clients"
                    />
                </ListItemIcon>
                <ListItemText primary={t("nav.clients")}/>
                {isOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
           </Link>

           <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                  <Link className="side-nav-link" to={`${url}/beneficiaries`}>
                    <ListItem button>
                        <ListItemIcon>
                          
                            <img
                                src={require("../menu-icons/customer.svg")}
                                alt="Bénéficiaires"
                            />
                         
                        </ListItemIcon>
                        <ListItemText primary={t("nav.beneficiaries")}/>
                    </ListItem>
                  </Link>

                </List>
           </Collapse>
        </>
    );
}

function SubNavFolder({ url, t }: any) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>

            <Link className="side-nav-link" to={`${url}/folders`}>  
              <ListItem button onClick={() => setIsOpen(!isOpen)}>
                <ListItemIcon>
                    <img
                        src={require("../menu-icons/bxl-bitcoin.svg")}
                        alt="Dossiers"
                    />
                </ListItemIcon>
                <ListItemText primary={t("nav.folders")}/>
                {isOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </Link>  

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                  <Link className="side-nav-link" to={`${url}/transfers`} >
                    <ListItem button>
                        <ListItemIcon>
                            <img
                                src={require("../menu-icons/placeholder.svg")}
                                alt={t("nav.transfers")}
                            />
                        </ListItemIcon>
                        <ListItemText primary={t("nav.transfers")}/>
                    </ListItem>
                  </Link>

                </List>
            </Collapse>
        </>
    );
}
