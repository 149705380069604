import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../../components/form-title/FormTitle";
import { useForm } from "react-hook-form";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import ClientService from "../../../services/client-service";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import ImagePreview from "../../../components/image-preview/ImagePreview";
import "./FormClient.scss";
import SubmitComponent from "../../../components/submit-component/SubmitComponent";
import { ErrorForm } from "../../../components/ErrorForm";
import { Client, Division } from "../../../models/client";
import { ClientType } from "../../../models/client-type";
import AutocompleteControlled from "../../../components/fields-controlled/AutocompleteControlled";
import InputControlled from "../../../components/fields-controlled/InputControlled";
import CountryPhoneControlled from "../../../components/fields-controlled/CountryPhoneControlled";
import { FormLabel, TextField } from "@material-ui/core";
import ClientTypeService from "../../../services/client-type-service";
import DivisionService from "../../../services/division-service";
import { connect } from "react-redux";
import { User } from "../../../models/user";


interface IPropsFormClient {
    company_name: string;
    client_type: string;
    client_type_id: number;
    referent_name: string;
    photo: string;
    email: string;
    phone_number: string;
    mobile_number: string;
    //address: Address;
    //tva_number: string;
    //tva_exoneration: boolean;
    //tva_rate: number;
}

interface IFormProps {
    client?: Client;
    user: User;
}

function FormClient({ client, user }: IFormProps) {
    console.log(client)
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        control,
    } = useForm<IPropsFormClient>();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [typeList, setTypeList] = useState<ClientType[]>();
    const [imagePreview] = useState<any>(null);
    /*const [isExo, setIsExo] = useState<any>(
        client?.tva_exoneration ? client?.tva_exoneration : false
    );*/
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [mainClients] = useState<Client[]>(user.isClient() ? user.getMainClientOwned() : []);

    const [readOnly] = useState<boolean>(client && user.isClient() && !user.isOwner(client) ? true : false)
    const [disabledForm, setDisabledForm] = useState(readOnly);
    const [isActivateButton,setIsActivateButton]=useState(true)
    let history = useHistory();

    useEffect(() => {
        const getTypes = async () => {
            try {
                const dataService = new ClientTypeService();
                setTypeList(await dataService.all());
            } catch (error) {
                console.log("error", error);
            }
        };
        getTypes();
        const getDivisions = async () => {
            try {
                const divisionService = new DivisionService();
                setDivisions(await divisionService.all());
            } catch (error) {
                console.log("error", error);
            }
        };
        getDivisions();
    }, []);

    const onSubmit = async (data: any) => {
        setIsActivateButton(false)
        setErrorApi(new ErrorFormData());
        const service = new ClientService();
        try {
            //if (isExo) data.tva_rate = null;
            setDisabledForm(true);
            if (client) {
                await service.update(client.id, data);
                toastr.success(t("success"), t("success_update_client"));
                history.push(`/app/client/${client.id}`);
            } else {
                const newClient = await service.add(data);
                toastr.success(t("success"), t("success_add_client"));
                history.push(
                    `/app/client/${newClient.id}/address/add?default_address=1`
                );
            }
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true)
    };

    function renderClientParentField () {
        if (client) {
            return (<></>);
        }
        return (
            <>
                {mainClients.length > 1
                    ? <AutocompleteControlled
                        label={`${t("main_client")} *`}
                        name="parent"
                        disabled={disabledForm}
                        listItems={mainClients ? mainClients : []}
                        value={null}
                        required={true}
                        getOptionLabel={(option: any) => option.company_name}
                        control={control}
                        errors={errors}
                    />
                    : <input
                        type="hidden"
                        name={"parent_id"}
                        value={mainClients[0].id}
                        ref={control.register}
                    />
                }
            </>
        )
    }

    function renderAdminParentField () {
        return (
            <>
                {client && client.parent
                    ? <TextField
                        disabled={true}
                        label={t("main_client")}
                        value={client.parent.company_name}
                    />
                    : ''
                }
            </>
        )
    }

    return (
        <>
            <form
                className="form-react-provider"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="row">
                    <div className="col">

                        {user.isClient()
                            ? renderClientParentField()
                            : renderAdminParentField()
                        }

                        <FormTitle title={t("form.identity")} />

                        <div className="row row-md mt-3 provider-form-part">
                            <div className="col-12 col-md-6 col-lg-auto">
                                <div className="form-group">
                                    <InputControlled
                                        name="company_name"
                                        label={`${t("form.company_name")} *`}
                                        value={client?.company_name}
                                        required={true}
                                        disabled={disabledForm}
                                        control={control}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-auto">
                                <div className="form-group">
                                    <AutocompleteControlled
                                        label={`${t("form.company_type")} *`}
                                        name="client_type"
                                        disabled={disabledForm}
                                        listItems={typeList ? typeList : []}
                                        value={client?.client_type}
                                        required={true}
                                        getOptionLabel={(option: any) =>
                                            option.label
                                        }
                                        control={control}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-auto">
                                <div className="form-group">
                                    <AutocompleteControlled
                                        label={t("management_entity")}
                                        name="division"
                                        disabled={disabledForm}
                                        listItems={divisions ? divisions : []}
                                        value={client?.division}
                                        required={false}
                                        getOptionLabel={(option: any) => option.label}
                                        control={control}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-auto">
                                <div className="form-group">
                                    <label className="bold-label">{t("photo")}</label>
                                    <div className="flex_row">
                                        <input
                                            type="hidden"
                                            name="img_base64"
                                            ref={register()}
                                        />
                                        <ImagePreview
                                            disabledForm={disabledForm}
                                            existingImageUrl={client?.img_url}
                                            canUpdate={true}
                                            onUpdateImage={(imgBase64) => {
                                                setValue("img_base64", imgBase64);
                                            }}
                                        />
                                    </div>
                                </div>
                                {imagePreview ? (
                                    <div className="preview_image">
                                        <img src={imagePreview} alt="" />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <FormTitle title={t("form.contact")} margin={true} />
                <div className="row row-md mt-3 provider-form-part">
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group">
                            <InputControlled
                                name="referent_name"
                                label={`${t("form.referent_name")} *`}
                                value={client?.referent_name}
                                required={true}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group client-mail">
                            <InputControlled
                                name="email"
                                label={`${t("form.email")} *`}
                                value={client?.email}
                                required={true}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group fg-phone-input">
                            <FormLabel>{`${t("form.phone_number")} *`} </FormLabel>
                            <CountryPhoneControlled
                                name="phone_number"
                                label={t("form.phone_number")}
                                value={client?.phone_number}
                                required={true}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="field-group fg-phone-input">
                            <FormLabel>{`${t("form.mobile_number")} *`} </FormLabel>
                            <CountryPhoneControlled
                                name="mobile_number"
                                label={t("form.mobile_number")}
                                value={client?.mobile_number}
                                required={true}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                            />
                        </div>
                    </div>
                </div>

                {!readOnly && <SubmitComponent text={t("form.validate")} isActivate={isActivateButton}/>}

                <ErrorForm errorApi={errorApi} />
            </form>
        </>
    );
}

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(FormClient);

