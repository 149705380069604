import React, { useEffect, useState } from "react";
import { Provider } from "../../../models/provider";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import ProviderService from "../../../services/provider-service";
import { toastr } from "react-redux-toastr";
import SubmitComponent from "../../../components/submit-component/SubmitComponent";
import { ErrorForm } from "../../../components/ErrorForm";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputControlled from "../../../components/fields-controlled/InputControlled";
import { Button, Checkbox } from "@material-ui/core";
import { ProviderType } from "../../../models/provider-type";
import FormSection from "../../../components/form-section/FormSection";
import IPropsFormProvider from "../../../models/interfaces/providerForm";
import ImagePreview from "../../../components/image-preview/ImagePreview";
import { useHistory } from "react-router-dom";
import { Country } from "../../../models/country";
import CountryPhoneControlled from "../../../components/fields-controlled/CountryPhoneControlled";
import AutocompleteControlled from "../../../components/fields-controlled/AutocompleteControlled";
import { Prompt } from "react-router";
import "./FormProvider.scss";
import CarSvg from "../../design-dependencies/svgs/CarSvg";
import Colors from "../../design-dependencies/Colors";
import UserSvg from "../../design-dependencies/svgs/UserSvg";

interface IFormProps {
    provider?: Provider;
    providerTypes: Array<ProviderType>;
    countries: Array<Country>;
}

const FormProvider = ({ provider, providerTypes, countries }: IFormProps) => {
    const { t } = useTranslation();
    console.log(provider)

    const {register, handleSubmit, errors, setValue, control, getValues} =
        useForm<IPropsFormProvider>();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [errorType, setErrorType] = useState<string>();
    const [disabledForm, setDisabledForm] = useState(false);
    const [popupConfirmLeave, setPopupConfirmLeave] = useState(true);
    const [switchs, setSwitchs] = useState<any>({
        is_exonerated: !!provider?.is_exonerated || false,
        is_tender: !!provider?.is_tender || false,
        has_notif_email: provider ? provider?.has_notif_email : true,
    });
    const [isActivateButton, setIsActivateButton] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const providerService = new ProviderService();
    let history = useHistory();
    const [isExo, setIsExo] = useState<any>(
        provider?.is_exonerated ? provider?.is_exonerated : false
    );
    const [optionIds, setOptionIds] = useState<any>([]);
    const alertUser = (e: any) => {
        e.preventDefault();
        e.returnValue = "";
    };

    useEffect(() => {
        if (provider?.provider_type_id) {
            const providerOptionsIds =
                provider && provider?.provider_type_id
                    ? provider?.provider_type_id
                    : [];

            if (providerOptionsIds === 1 || providerOptionsIds === 2) {
                setOptionIds([providerOptionsIds]);
            }
            if (providerOptionsIds === 3) {
                setOptionIds([1, 2]);
            }
        }
        if (provider?.address?.country_id) {
            setValue("country_id", provider?.address?.country_id);
        }
        if (provider?.logo_key) {
            setValue("img_base64", provider?.logo_key);
        }
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, [provider, register, setValue]);

    useEffect(() => {
        register("provider_type_id");
        if (optionIds.length === 1) {
            setValue("provider_type_id", optionIds[0]);
        }
        if (optionIds.length === 2) {
            setValue("provider_type_id", 3);
        }
    }, [optionIds, register, setValue]);
    //Update or Post on submit
    const onSubmit = async (data: any, e: any) => {
        setIsActivateButton(false);
        setErrorApi(new ErrorFormData());
        try {
            setLoadingSubmit(true);
            setDisabledForm(true);
            setPopupConfirmLeave(false);
            if (isExo) {
                data.tva_rate = null;
                data.tva_number = null;
            }
            if (!provider) {
                const newProvider = await providerService.add(data);
                toastr.success(t("success"), t("success_create_provider"));
                history.push(`/app/provider/${newProvider.id}`)
            } else {
                console.log(data);
                await providerService.put(
                    { ...data, user_id: provider?.user?.id },
                    provider.id
                );
                toastr.success(t("success"), t("success_save_provider"));
            }
            setLoadingSubmit(false);
            setDisabledForm(false);
        } catch (error) {
            setDisabledForm(false);
            setLoadingSubmit(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true);
    };

    const onChangeTypes = (optionId: number, value: string) => {
        if (value === "true") {
            const newOptionIds: any = [
                ...optionIds.filter((id: any) => id !== optionId),
            ];
            setOptionIds(newOptionIds);
        } else {
            const newOptionIds = [...optionIds, optionId];
            setOptionIds(newOptionIds);
        }
    };

    //Handle switchs - is_tender and is_exonerated
    const onChangeSwitchs = (event: any) => {
        const { name, checked } = event.currentTarget;
        if (name === "is_exonerated") {
            setIsExo(checked);
        }
        setSwitchs({ ...switchs, [name]: checked });
    };
    const getLabelType = (type: string) => {
        switch (type) {
            case "Individuel":
                return "form.individuel";
            case "Group":
                return "form.group";
            default:
                return "";
        }
    };

    const hasOptionId = (optionId: number) => {
        return optionIds?.find((id: any) => id === optionId) !== undefined;
    };

    return (
        <>
            <Prompt
                when={popupConfirmLeave}
                message={`${t("lost_input")}\n${t("sure_to_back")}`}
            />
            <form
                className="form-react-provider"
                onSubmit={handleSubmit(onSubmit)}
            >
                {/* Identity section */}
                <FormSection title={t("form.identity")}>
                    <div className="form-group pdt2">
                        <InputControlled
                            name="company_name"
                            label={`${t("form.company_name")} *`}
                            value={provider ? provider.company_name : ""}
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>
                    <div className="form-group section-image-upload flexed-img">
                        <div className="col-12 col-lg-auto">
                            <div className="form-group flexed-img">
                                <div className="d-lg-flex">
                                    <label>{t("logo")}</label>
                                    <input
                                        type="hidden"
                                        name="img_base64"
                                        ref={register()}
                                    />
                                    <ImagePreview
                                        disabledForm={disabledForm}
                                        canUpdate={true}
                                        existingImageUrl={provider?.img_url}
                                        onUpdateImage={(imgBase64) =>
                                            setValue("img_base64", imgBase64)
                                        }
                                        isCompany
                                    />
                                    {provider && (
                                        <div className="d-flex flex-column">
                                            <Button
                                                className="btn-redirect"
                                                variant="contained"
                                                onClick={() =>
                                                    history.push(
                                                        `/app/vehicles?providerName=${provider?.company_name}&providerId=${provider?.id}`
                                                    )
                                                }
                                            >
                                                <span
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                >
                                                    {t(
                                                        "redirect_to_vehicles_provider"
                                                    ) + provider?.company_name}
                                                </span>
                                                <CarSvg fill={Colors.white} />
                                            </Button>
                                            <Button
                                                className="btn-redirect"
                                                variant="contained"
                                                onClick={() =>
                                                    history.push(
                                                        `/app/drivers?providerId=${provider?.id}`
                                                    )
                                                }
                                            >
                                                <span
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                >
                                                    {t(
                                                        "redirect_to_drivers_provider"
                                                    ) + provider?.company_name}
                                                </span>
                                                <UserSvg fill={Colors.white} />
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </FormSection>
                {/* Contact section */}
                <FormSection title={t("form.contact")} marginTitle>
                    <div className="row row-md">
                        <div className="col provider-lastname">
                            <div className="form-group">
                                <InputControlled
                                    name="lastname"
                                    label={`${t("form.lastname")} * `}
                                    value={
                                        provider ? provider.user?.lastname : ""
                                    }
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col provider-firstname">
                            <div className="form-group">
                                <InputControlled
                                    name="firstname"
                                    label={`${t("form.firstname")} * `}
                                    value={
                                        provider ? provider.user?.firstname : ""
                                    }
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col provider-mail">
                            <div className="form-group">
                                <InputControlled
                                    name="email"
                                    label={`${t("form.email")} *`}
                                    value={provider ? provider.email : ""}
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group has-phone-input-as-input-height">
                                <CountryPhoneControlled
                                    name="phone"
                                    label={`${t("form.phone")} *`}
                                    value={provider ? provider.phone : ""}
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row row-md mt-3">
                        <div className="col provider-street">
                            <div className="form-group">
                                <InputControlled
                                    name="street"
                                    label={`${t("form.street")} *`}
                                    value={
                                        provider ? provider.address?.street : ""
                                    }
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col provider-misc">
                            <div className="form-group">
                                <InputControlled
                                    name="misc"
                                    label={`${t("form.misc")} *`}
                                    value={
                                        provider ? provider.address?.misc : ""
                                    }
                                    required={false}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col provider-post-code">
                            <div className="form-group">
                                <InputControlled
                                    name="post_code"
                                    label={`${t("form.post_code")} *`}
                                    value={
                                        provider
                                            ? provider.address?.post_code
                                            : ""
                                    }
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col provider-city">
                            <div className="form-group">
                                <InputControlled
                                    name="city"
                                    label={`${t("form.city")} * `}
                                    value={
                                        provider ? provider.address?.city : ""
                                    }
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <AutocompleteControlled
                                    label={`${t("form.country")} *`}
                                    name="country"
                                    disabled={disabledForm}
                                    listItems={countries ? countries : []}
                                    value={
                                        provider?.address?.country
                                            ? provider.address?.country
                                            : null
                                    }
                                    required={true}
                                    getOptionLabel={(option: any) =>
                                        option.label
                                    }
                                    control={control}
                                    errors={errors}
                                    onChange={(e: any) =>
                                        setValue("country_id", e.id)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </FormSection>
                {/* Divers section */}
                <FormSection
                    title={t("form.miscellaneous")}
                    className="d-block"
                    //className="mgt1 form-part-align-center"
                    marginTitle
                >
                    <div className="row row-md mt-3">
                        {!switchs.is_exonerated && (
                            <div className="col-auto">
                                <div className="form-group provider-tva-number">
                                    <InputControlled
                                        name="tva_number"
                                        label={`${t("form.tva_number")} *`}
                                        value={
                                            provider ? provider.tva_number : ""
                                        }
                                        required={!isExo}
                                        disabled={disabledForm}
                                        control={control}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="col">
                            <div className="d-flex flex-wrap">
                                <div className="form-group mgl4 provider-is-tender switchs col-2">
                                    <FormControlLabel
                                        className="switch-label-top mx-0"
                                        control={
                                            <Switch
                                                inputRef={register}
                                                name="is_exonerated"
                                                className="switch-ios"
                                                onChange={onChangeSwitchs}
                                                checked={switchs.is_exonerated}
                                            />
                                        }
                                        label={t("form.is_exonerated")}
                                        labelPlacement="top"
                                    />
                                    {errors.is_exonerated && (
                                        <p>{errors.is_exonerated.message}</p>
                                    )}
                                </div>
                                {/* {!isExo && (
                                    <div className="form-group mgl4 client-tva-number col-2">
                                        <InputControlled
                                            name="tva_rate"
                                            label={t("form.tva_rate")}
                                            value={provider?.tva_rate}
                                            required={false}
                                            disabled={disabledForm}
                                            control={control}
                                            errors={errors}
                                        />
                                    </div>
                                )} */}
                                <div className="form-group mgl4 provider-is-tender switchs mgr4 col-4">
                                    <FormControlLabel
                                        className="switch-label-top mx-0"
                                        control={
                                            <Switch
                                                inputRef={register}
                                                name="is_tender"
                                                className="switch-ios"
                                                onChange={onChangeSwitchs}
                                                checked={switchs.is_tender}
                                            />
                                        }
                                        label={t("form.is_tender")}
                                        labelPlacement="top"
                                    />
                                    {errors.is_tender && (
                                        <p>{errors.is_tender.message}</p>
                                    )}
                                </div>

                                <div className="form-group mgl4 provider-has-notif-email switchs mgr4 col-4">
                                    <FormControlLabel
                                        className="switch-label-top mx-0"
                                        control={
                                            <Switch
                                                inputRef={register}
                                                name="has_notif_email"
                                                className="switch-ios"
                                                onChange={onChangeSwitchs}
                                                checked={switchs.has_notif_email}
                                            />
                                        }
                                        label={t("form.has_notif_email")}
                                        labelPlacement="top"
                                    />
                                    {errors.has_notif_email && (
                                        <p>{errors.has_notif_email.message}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-md mt-3">
                        <div className="form-group provider-type checkboxs">
                            <span className="label-form">{`${t(
                                "form.type"
                            )} *`}</span>
                            <div className="checkboxs-section">
                                {providerTypes.map((type) => {
                                    if (type.id !== 3) {
                                        return (
                                            <div key={type.id}>
                                                <FormControlLabel
                                                    key={`form_provider_type_id_${type.id}`}
                                                    control={
                                                        <Checkbox
                                                            checked={hasOptionId(
                                                                type.id
                                                            )}
                                                            value={hasOptionId(
                                                                type.id
                                                            )}
                                                            ref={register}
                                                            onChange={(e) =>
                                                                onChangeTypes(
                                                                    type.id,
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            name="provider_type_id"
                                                        />
                                                    }
                                                    label={t(
                                                        getLabelType(type.label)
                                                    )}
                                                />
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                            {errorType && (
                                <p style={{ color: "red", fontSize: 14 }}>
                                    {errorType}
                                </p>
                            )}
                        </div>

                        <div className="form-group provider-is-tender checkboxs" />
                    </div>
                    <div className="row row-md mt-3">
                        <div className="col-xl-4">
                            <div className="form-group provider-licence">
                                <InputControlled
                                    name="licence_number"
                                    label={`${t("form.licence_number")} *`}
                                    value={
                                        provider ? provider.licence_number : ""
                                    }
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="form-group provider-licence">
                                <InputControlled
                                    name="iban"
                                    label={t("form.iban")}
                                    value={provider ? provider.iban : ""}
                                    required={false}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="form-group provider-licence">
                                <InputControlled
                                    name="bic"
                                    label={t("form.bic")}
                                    value={provider ? provider.bic : ""}
                                    required={false}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                    </div>
                </FormSection>

                <SubmitComponent
                    loading={loadingSubmit}
                    text={t("form.validate")}
                    isActivate={isActivateButton}
                />

                <ErrorForm errorApi={errorApi} />
            </form>
        </>
    );
};

export default FormProvider;
