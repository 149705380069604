import React, { useRef, useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import DefaultBackground from "../../imported-images/user-placeholder.png";
import DefaultBackgroundCompany from "../../imported-images/company-placeholder.png";
import { Add } from "@material-ui/icons";

interface IProps {
    canUpdate?: boolean;
    disabledForm?: boolean;
    existingImageUrl?: any;
    defaultImage?: any;
    size?: string;
    onUpdateImage: (imgBase64: any, url?:any) => void;
    onClick?: () => void;
    isCompany?: boolean;
}
const ImagePreview: React.FunctionComponent<IProps> = ({
    canUpdate,
    disabledForm,
    existingImageUrl,
    defaultImage = DefaultBackground,
    size = "medium",
    isCompany = false,
    onUpdateImage,
}) => {
    const inputFileRef = useRef<any>(null);
    const defaultImageCompany = DefaultBackgroundCompany;
    const [image, setImage] = useState<any>(
        existingImageUrl
            ? existingImageUrl
            : isCompany
            ? defaultImageCompany
            : defaultImage
    );

    const targetFile = () => {
        if (!disabledForm) {
            inputFileRef.current.click();
        }
    };

    const preview = (files: any) => {
        if (files.length === 0) return;

        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
            setImage(reader.result);
            if (reader.result) {
                onUpdateImage(reader.result,files);
            }
        };
    };

    const sizeImage =
        size === "medium" ? "110px" : size === "small" ? "40px" : "55px";
    const sizeAdd =
        size === "medium" ? "45px" : size === "small" ? "16px" : "25px";
    const lineHeightAdd =
        size === "medium" ? "48px" : size === "small" ? "16px" : "25px";
    const backgroundSize =
        size === "medium" ? "110px" : size === "small" ? "40px" : "55px";
    const rightAdd =
        size === "medium" ? "-15px;" : size === "small" ? "-3px" : "-6px";
    const bottomAdd =
        size === "medium" ? "10px;" : size === "small" ? "0px" : "3px";
    const sizeIconAdd =
        size === "medium" ? "24px" : size === "small" ? "12px" : "22px";

    const useStyles = makeStyles((theme) => ({
        imageBackground: {
            backgroundImage: `url(${image})`,
            backgroundPosition: "center",
            backgroundSize: backgroundSize,
            backgroundRepeat: "no-repeat",
            width: sizeImage,
            height: sizeImage,
        },
        imageAdd: {
            width: sizeAdd,
            height: sizeAdd,
            lineHeight: lineHeightAdd,
            right: rightAdd,
            bottom: bottomAdd,
        },
    }));
    const classes = useStyles();

    return (
        <>
            <div 
                className="user-image-upload d-flex"
            >
                {canUpdate ? (
                    <input
                        accept="image/*"
                        className="hidden"
                        ref={inputFileRef}
                        onChange={(e) => preview(e.target.files)}
                        type="file"
                    />
                ) : (
                    <></>
                )}

                <label>
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        className={`default`}
                        classes={{
                            label: `MuiIconButton-label ${classes.imageBackground}`,
                        }}
                        onClick={() => targetFile()}
                    >
                        {canUpdate ? (
                            <span
                                className={`material-icons ${classes.imageAdd}`}
                            >
                                <Add
                                    style={{ height: sizeIconAdd }}
                                />
                            </span>
                        ) : (
                            <></>
                        )}
                    </IconButton>
                </label>
            </div>
        </>
    );
};

export default ImagePreview;
