import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../../components/form-title/FormTitle";
import { useForm } from "react-hook-form";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { Prompt } from "react-router";
import { Button, TextareaAutosize, TextField } from "@material-ui/core";
import SubmitComponent from "../../../components/submit-component/SubmitComponent";
import { ErrorForm } from "../../../components/ErrorForm";
import { Client } from "../../../models/client";
import FolderService from "../../../services/folder-service";
import { Extra, Folder } from "../../../models/folder";
import { Add } from "@material-ui/icons";
import "./FormFolder.scss";
import { Beneficiary } from "../../../models/beneficiary";
import SubFormExtra from "./Subforms/SubFormExtra";
import AutocompleteControlled from "../../../components/fields-controlled/AutocompleteControlled";
import SubFormBeneficiary from "./Subforms/SubFormBeneficiary";
import InputControlled from "../../../components/fields-controlled/InputControlled";
import { formatDatePicker } from "../../../utils/DateHelper";
import ClientService from "../../../services/client-service";
import { Address } from "../../../models/address";
import ModalWantCancelled from "../../../components/content-section/return-action/modal-want-cancelled/ModalWantCancelled";
import AdministratorService from "../../../services/administrator-service";
import { User } from "../../../models/user";
import { DataPaginate } from "../../../models/utils/data-paginate";
import { connect } from "react-redux";
import InputDateControlled from "../../../components/fields-controlled/InputDateControlled";
import ProviderService from "../../../services/provider-service";
import FormSection from "../../../components/form-section/FormSection";

interface IPropsForm {
    name: string;
    future_code: number;
    client_id: number | null;
    client: Client | null;
    start_date: Date;
    end_date: Date;
    referent_id: number;
    sms_detail: string;
}

interface IFormProps {
    folder?: Folder;
    allBeneficiaries: Beneficiary[];
    clients: Client[];
    user: User;
}

const FormFolder = ({
    folder,
    allBeneficiaries,
    clients,
    user,
}: IFormProps) => {
    const { t } = useTranslation();
    const { handleSubmit, errors, control, register, setValue } =
        useForm<IPropsForm>();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const readOnly =
        folder?.client && user.isClient() && user.isReader(folder.client)
            ? true
            : false;
    const [disabledForm, setDisabledForm] = useState(readOnly);
    const [clientValue, setClientValue] = React.useState(folder?.client);
    const [clientBeneficiaryList, setClientBeneficiaries] =
        useState<Beneficiary[]>();
    const [extras, setExtras] = React.useState(
        folder?.extras ? folder?.extras : []
    );
    const [beneficiaries, setBeneficiaries] = React.useState<Beneficiary[]>();
    const clientService = new ClientService();
    const [address, setAddress] = useState<Address>();
    const [wantCancelled, setWantCancelled] = useState(false);
    const [popupConfirmLeave, setPopupConfirmLeave] = useState(true);
    const [referents, setReferents] = useState<DataPaginate>(
        new DataPaginate()
    );
    const [isActivateButton, setIsActivateButton] = useState(true);
    const [lastFolder, setLastFolder] = useState<Folder>();
    const [smsContent, setSmsContent] = useState<string>(folder && folder.sms_detail ? folder.sms_detail.trim() : "");
    const [counter, setCounter] = useState<number>(0);

    useEffect(() => {
        console.log(smsContent)
        setCounter(smsContent ? smsContent.length : 0);
        setValue("sms_detail", smsContent);
    }, [smsContent]);

    useEffect(() => {
        register("sms_detail");
        setValue("sms_detail", smsContent);
    }, [register, setValue, smsContent]);

    let history = useHistory();
    const clientChange = async (selected: Client) => {
        if (selected?.id) {
            setClientValue(selected);
            changeAddress(selected);

        } else {
            setClientValue(null);
        }
    };
    useEffect(() => {
        if (user && user.isAdmin()) {
            const fetchReferents = async () => {
                const adminService = new AdministratorService();
                setReferents(
                    await adminService.getAdministrators(null, null, "all")
                );
            };
            if (!folder) {
                const getLastFolder = async () => {
                    try {
                        const folderService = new FolderService();
                        const folderPaginate = await folderService.all(
                            null,
                            null,
                            "1"
                        );
                        console.log(folderPaginate.data[0]);
                        setLastFolder(folderPaginate.data[0]);
                    } catch (error) {
                        console.log(error);
                        setErrorApi(new ErrorFormData(error.response.data));
                    }
                };
                getLastFolder();
            }
            fetchReferents();
        }

        if(folder && folder.client){
            changeAddress(folder.client);
        }
    }, [register, setValue, user]);

    useEffect(() => {
        let filtered = allBeneficiaries?.filter(
            (beneficary: Beneficiary) =>
                beneficary.client_id === clientValue?.id
        );

        setClientBeneficiaries(filtered);

        if (clientValue?.id === folder?.client_id) {
            setBeneficiaries(
                folder?.beneficiaries ? folder?.beneficiaries : []
            );
        } else {
            setBeneficiaries([]);
        }
    }, [clientValue, allBeneficiaries, folder]);

    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, [clients, user]);
    const alertUser = (e: any) => {
        e.preventDefault();
        e.returnValue = "";
    };

    const changeAddress = (client: Client) => {
        if(client.address){
            setAddress(client.address);

        } else if(client.addresses && client.addresses.length){
            setAddress(client.addresses[0]);
        }
    }

    const onSubmit = async (data: any, e: any) => {
        if(data.address){
            data.billing_adress = data.address.toString();
        }
        setIsActivateButton(false);
        data.beneficiaries = beneficiaries?.map((beneficiary) => {
            return { beneficiary_id: beneficiary.id };
        });

        setErrorApi(new ErrorFormData());
        const service = new FolderService();
        try {
            setDisabledForm(true);
            if (folder) {
                try {
                    console.log(data)
                    await service.update(folder.id, data);
                    toastr.success(t("success"), t("success_update_folder"));
                    setPopupConfirmLeave(false);
                    history.push(`/app/folder/${folder.id}/edit?tab=transfers`);
                } catch (error) {
                    setErrorApi(new ErrorFormData(error.response.data));
                }
            } else {
                try {
                    const newFolder = await service.add(data);
                    toastr.success(t("success"), t("success_add_folder"));
                    setPopupConfirmLeave(false);
                    history.push(
                        `/app/folder/${newFolder.id}/edit?tab=transfers`
                    );
                } catch (error) {
                    if (error.response.data.original.error)
                        toastr.error(
                            t("error"),
                            error.response.data.original.error
                        );
                    setErrorApi(new ErrorFormData(error.response.data));
                }
            }
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true);
        setErrorApi(new ErrorFormData());
        setDisabledForm(false);
    };

    const updateSubForm = (index: number, beneficiary_id: number) => {
        if (beneficiaries && clientBeneficiaryList) {
            const newBenificiary = clientBeneficiaryList.find(
                (item) => item.id === beneficiary_id
            );
            let newBeneficaries = [...beneficiaries];
            newBeneficaries[index] = newBenificiary
                ? newBenificiary
                : newBeneficaries[index];

            setBeneficiaries(newBeneficaries);
        }
    };

    const deleteSubForm = (index: number) => {
        if (beneficiaries) {
            setBeneficiaries(
                beneficiaries.filter((b: Beneficiary, i: number) => i !== index)
            );
        }
    };

    const updateExtra = (index: number, key: any, value: any) => {
        if (key === "price") {
            extras[index].price = value;
        } else if (key === "tva") {
            extras[index].tva = value;
        } else if (key === "type") {
            extras[index].type = value;
        }
    };

    const deleteExtra = (index: number) => {
        if (extras) {
            setExtras(extras.filter((e: Extra, i: number) => i !== index));
        }
    };

    const getFutureFolderCode = (code?: string) => {
        if (code) {
            const folderCodeWithoutId = code.replace(/\d+/, "");
            const folderIdInCode = code.match(/\d+/);
            const newId = String(
                folderIdInCode ? Number(folderIdInCode) + 1 : ""
            );
            let beforeId = "";

            if (String(folderIdInCode).length > newId.length) {
                for (let i = 0; i < newId.length; i++) {
                    beforeId = beforeId + "0";
                }
            }
            const newCode = folderCodeWithoutId + beforeId + newId;

            return newCode;
        }
        return code;
    };

    return (
        <>
            <Prompt
                when={popupConfirmLeave}
                message={`${t("lost_input")}\n${t("sure_to_back")}`}
            />
            <form
                className="form-react-provider"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormTitle title={t("entity.client")} margin={false} />
                <div className="provider-form-part d-block">
                    <div className="row row-md">
                        <div className="col-12 col-xl-3">
                            <div className="form-group">
                                <AutocompleteControlled
                                    label={`${t("referent_client")} *`}
                                    name="client"
                                    disabled={disabledForm}
                                    listItems={clients ? clients : []}
                                    value={clientValue}
                                    required={true}
                                    getOptionLabel={(option: any) =>
                                        option.company_name
                                    }
                                    renderOption={(option: any) => (
                                        <React.Fragment>
                                            {option.parent_id &&
                                            user?.isAdmin() ? (
                                                <span className="spacer">
                                                    {" "}
                                                    -{" "}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                            {option.company_name}
                                        </React.Fragment>
                                    )}
                                    control={control}
                                    errors={errors}
                                    onChange={clientChange}
                                />
                            </div>
                        </div>
                        
                        {clientValue ? (
                            <>
                                <div className="col-12 col-lg-6 col-xl-3">
                                    <div className="form-group">
                                        {clientValue.addresses && <AutocompleteControlled
                                            label={t("form.billing_adress")}
                                            name="address"
                                            disabled={disabledForm}
                                            listItems={
                                                clientValue.addresses ? clientValue.addresses : []
                                            }
                                            value={
                                                folder?.address ? folder.address : clientValue?.address
                                                    ? clientValue.address
                                                    : null
                                            }
                                            required={true}
                                            getOptionLabel={(option: Address) =>
                                                option.toString()
                                            }
                                            control={control}
                                            errors={errors}
                                        />}
                                        {!clientValue.addresses && 
                                            <>
                                                <span>Le client n'a aucune adresse</span>
                                                <Button className="btn-form btn-icon"
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    onClick={() => history.push(`/app/client/${clientValue.id}/address/add`) }>
                                                        Ajouter une adresse
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 col-xl-3">
                                    <div className="form-group">
                                        <div className="simples-input-display-column-group">
                                            <div className="simple-input-display">
                                                <strong>{t("form.phone")} : </strong>
                                                <span>{clientValue.getPhoneForList()}</span>
                                            </div>
                                            <div className="simple-input-display">
                                                <strong>{t("form.email")} : </strong>
                                                <span>{clientValue.email}</span>
                                            </div>
                                        </div>
                                        {/* <TextField
                                            disabled={true}
                                            variant="outlined"
                                            label={t("form.phone")}
                                            value={clientValue.getPhoneForList()}
                                        /> */}
                                    </div>
                                </div>
                                {/* <div className="col-12 col-md-6 col-xxl">
                                    <div className="form-group">
                                        <TextField
                                            disabled={true}
                                            variant="outlined"
                                            label={t("form.email")}
                                            value={clientValue.email}
                                        />
                                    </div>
                                </div> */}
                                <div className="col-12 col-lg-3">
                                    <div className="form-group">
                                        <div className="simples-input-display-column-group">
                                            <div className="simple-input-display">
                                                <strong>{t("form.referent_name")} : </strong>
                                                <span>{clientValue.referent_name}</span>
                                            </div>
                                            {/*<div className="simple-input-display">
                                                <strong>{t("form.billing_adress")} : </strong>
                                                <span>{ address ? address?.toString() : " " }</span>
                                            </div>*/}
                                        </div>
                                        {/* <TextField
                                            disabled={true}
                                            variant="outlined"
                                            label={t("form.referent_name")}
                                            value={clientValue.referent_name}
                                        /> */}
                                    </div>
                                </div>
                                {/* <div className="col-12 col-md-6 col-xxl">
                                    <div className="form-group">
                                        <TextField
                                            className="billing-adress"
                                            disabled={true}
                                            multiline={false}
                                            variant="outlined"
                                            label={t("form.billing_adress")}
                                            value={
                                                address
                                                    ? address?.toString()
                                                    : " "
                                            }
                                        />
                                    </div>
                                </div> */}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                <FormTitle 
                    title={t("entity.folder")} 
                    margin={true} 
                    children={
                        <>
                            {folder && 
                                <div className="num-folder">{folder.code}</div>
                            }
                        </>
                    } 
                />

                <div className="provider-form-part d-block">
                    <div className="row row-md">
                        <div className="col col-md-4 col-lg-3">
                            <div className="form-group">
                                <InputControlled
                                    name="name"
                                    label={`${t("form.folder_name")} *`}
                                    value={folder?.name}
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        {user?.isAdmin() && (
                            <div className="col">
                                <div className="form-group">
                                    <AutocompleteControlled
                                        label={t("referent_sparklane")}
                                        name="referent"
                                        disabled={disabledForm}
                                        listItems={
                                            referents.data ? referents.data : []
                                        }
                                        value={
                                            folder?.referent
                                                ? folder.referent
                                                : user
                                        }
                                        required={false}
                                        getOptionLabel={(option: User) =>
                                            option.getFullName()
                                        }
                                        control={control}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="col-6 col-xl">
                            <div className="form-group">
                                <InputDateControlled
                                    name="start_date"
                                    label={`${t("form.start_date")} *`}
                                    value={formatDatePicker(folder?.start_date)}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="col-6 col-xl">
                            <div className="form-group">
                                <InputDateControlled
                                    name="end_date"
                                    label={`${t("form.end_date")} *`}
                                    value={formatDatePicker(folder?.end_date)}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <FormTitle title={t("sms_details")} margin={true} />
                <div className="form_row align-items-end">
                    <TextareaAutosize
                        disabled={disabledForm}
                        onChange={(e) => setSmsContent(e.target.value)}
                        value={smsContent}
                        className="textarea col-8"
                        aria-label="empty textarea"
                        rowsMin={2} rowsMax={10}
                        maxLength={40}
                        placeholder={t('comment')}
                    />
                    <span className="col-4">{counter}/40</span>
                </div>
                <FormTitle title={t("nav.beneficiaries")} margin={true} />
                {beneficiaries && clientBeneficiaryList ? (
                    <>
                        <div className="multi">
                            <div className="multi-container">
                                {beneficiaries.map(
                                    (beneficiary: any, index: number) => (
                                        <div
                                            key={
                                                "beneficiary-" +
                                                index +
                                                "id-" +
                                                beneficiary
                                                    ? beneficiary.id
                                                    : "unkown"
                                            }
                                            className="beneficiary"
                                        >
                                            <SubFormBeneficiary
                                                values={beneficiary}
                                                index={index}
                                                beneficiariesList={
                                                    clientBeneficiaryList
                                                        ? clientBeneficiaryList
                                                        : []
                                                }
                                                disabled={disabledForm}
                                                control={control}
                                                errors={errors}
                                                onUpdate={(
                                                    index: number,
                                                    beneficiary_id: number
                                                ) =>
                                                    updateSubForm(
                                                        index,
                                                        beneficiary_id
                                                    )
                                                }
                                                onDelete={(index: number) =>
                                                    deleteSubForm(index)
                                                }
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                            <div className="multi-controls mgl4">
                                {!disabledForm && (
                                    <Add
                                        className="add"
                                        onClick={() => {
                                            setBeneficiaries([
                                                ...beneficiaries,
                                                new Beneficiary({}),
                                            ]);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}

                <FormTitle title={t("form.extras")} margin={true} />
                <div className="multi">
                    <div className="multi-container">
                        {extras.map((extra: any, index: number) => (
                            <div
                                key={
                                    "extra-" +
                                    index +
                                    "-time" +
                                    extra.current_time
                                }
                                className="extra"
                            >
                                <SubFormExtra
                                    extra={extra}
                                    index={index}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                    onDelete={(index: number) =>
                                        deleteExtra(index)
                                    }
                                    onUpdate={(
                                        index: number,
                                        key: string,
                                        value: any
                                    ) => updateExtra(index, key, value)}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="multi-controls mgl4">
                        {!disabledForm && (
                            <Add
                                className="add-supplement"
                                onClick={() => {
                                    setExtras([...extras, new Extra()]);
                                }}
                            />
                        )}
                    </div>
                </div>

                {!readOnly && (
                    <SubmitComponent
                        text={
                            folder && folder.id
                                ? t("form.update")
                                : t("form.validate")
                        }
                        isActivate={isActivateButton}
                    />
                )}
                <ErrorForm errorApi={errorApi} />
            </form>
            <ModalWantCancelled
                isOpen={wantCancelled}
                closeModal={() => setWantCancelled(false)}
                goBack={() => history.goBack()}
            />
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(FormFolder);
