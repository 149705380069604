import React, { useState } from "react";
import { Button, Icon } from "@material-ui/core";
import ImagePreview from "../../../components/image-preview/ImagePreview";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import { useTranslation } from "react-i18next";
import ModalAcceptProvider from "../modal-accept-provider/ModalAcceptProvider";
import { Assignment } from "../../../models/assignment";
import TransferService from "../../../services/transfer-service";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { Transfer } from "../../../models/transfer";
import ModalUnassignment from "../list-transfers/modal-unassignment/ModalUnassignment";

interface IItemProvider {
    assignment: Assignment;
    transfer: Transfer;
    setError: (e: any) => void;
    provider?: any;
    onRefresh: () => void;
}

const ItemProviderTenderChoice: React.FunctionComponent<IItemProvider> = ({
    assignment,
    transfer,
    setError,
    provider,
    onRefresh,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showUnasign, setShowUnassign] = useState<boolean>(false);

    const chooseProvider = async () => {
        try {
            setLoading(true);
            const service = new TransferService();
            await service.chooseProvider(
                assignment.transfer_id,
                assignment.id,
                { status: true }
            );
            setLoading(false);
            toastr.success(t("success"), t("success_provider_choosen"));
            history.push("/app/transfers");
        } catch (error) {
            setLoading(false);
            setError(new ErrorFormData(error.response.data));
        }
    };
    return (
        <>
            <BodyTableRow>
                <BodyTableCell align="center">
                    <div className="upload-image-table">
                        <ImagePreview
                            size="litle"
                            existingImageUrl={provider?.img_url}
                            onUpdateImage={() => null}
                        />
                    </div>
                </BodyTableCell>
                <BodyTableCell align="center">
                    {provider && provider.company_name}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {provider && provider.phone}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {provider && provider.email}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {assignment.response?.driver?.getFullName()}
                </BodyTableCell>
                <BodyTableCell align="center" className="tag-status">
                    <div
                        className={`tag-table-section`}
                        style={{ position: "relative" }}
                    >
                        {assignment?.status ? (
                            <div className="tag-table-content">
                                {transfer.provider_id ===
                                assignment.provider_id ? (
                                    <div
                                        className="tag-table provider-choice to-upper"
                                        style={{
                                            backgroundColor: "#A1E89B",
                                        }}
                                    >
                                        <span className="to-upper">
                                            {t("admin_status.validated")}
                                        </span>
                                    </div>
                                ) : (
                                    <div
                                        className="tag-table provider-choice to-upper"
                                        style={{
                                            backgroundColor:
                                                assignment.getColorStatus(),
                                        }}
                                    >
                                        <span className="to-upper">
                                            {assignment.isPending()
                                                ? t("waiting_response")
                                                : t(
                                                      `admin_status.${assignment.status}`
                                                  )}
                                        </span>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="tag-table-content">
                                <div className="tag-table provider-choice">
                                    <span className="to-upper"></span>
                                </div>
                            </div>
                        )}
                    </div>
                </BodyTableCell>

                {assignment && assignment.isAccepted() ? (
                    <>
                        <BodyTableCell align="center">
                            {assignment.response?.price}€
                        </BodyTableCell>
                    </>
                ) : (
                    <>
                        <BodyTableCell align="center"></BodyTableCell>
                    </>
                )}
                <BodyTableCell align="center" className="">
                    {assignment &&
                        assignment?.isAccepted() &&
                        transfer.canChoiceProvider() && (
                            <>
                                <Button
                                    className="btn-form btn-icon accept-provider"
                                    style={{ minWidth: "120px" }}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => setIsOpen(true)}
                                    disabled={transfer.provider_id !== null}
                                >
                                    <span>{t("validate")}</span>
                                    <Icon>check</Icon>
                                </Button>
                                <ModalAcceptProvider
                                    isOpen={isOpen}
                                    closeDialog={() => setIsOpen(false)}
                                    provider={provider}
                                    handleAccept={() => chooseProvider()}
                                    driverName={() =>
                                        assignment.response?.driver?.getFullName()
                                    }
                                    loading={loading}
                                />
                            </>
                        )}
                    {assignment && (
                        <>
                            <Button
                                className="btn-form btn-icon accept-provider"
                                style={{
                                    minWidth: "120px",
                                    marginLeft: "15px",
                                }}
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => setShowUnassign(true)}
                                disabled={false}
                            >
                                <span>{t("cancel")}</span>
                                <Icon>close</Icon>
                            </Button>
                            <ModalUnassignment
                                isOpen={showUnasign}
                                transfer={transfer}
                                closeDialog={() => setShowUnassign(false)}
                                providerId={provider.id}
                                onRefresh={() => onRefresh()}
                                from="admin"
                            />
                        </>
                    )}
                </BodyTableCell>
            </BodyTableRow>
        </>
    );
};

export default ItemProviderTenderChoice;
