import {
    Button,
    Checkbox,
    CircularProgress,
    Icon,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { ErrorForm } from "../../../components/ErrorForm";
import FormTitle from "../../../components/form-title/FormTitle";
import Pagination from "../../../components/pagination/pagination";
import HeadTableCell from "../../../components/table/HeadTableCell";
import { Assignment } from "../../../models/assignment";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { Provider } from "../../../models/provider";
import { Transfer } from "../../../models/transfer";
import { DataPaginate } from "../../../models/utils/data-paginate";
import TransferService from "../../../services/transfer-service";
import { handleCheckBoxChange } from "../../../utils/HandleCheckboxs";
import ItemProviderChoice from "./ItemProviderChoice";
import ItemProviderTenderChoice from "./ItemProviderTenderChoice";
import { connect } from "react-redux";
import { User } from "../../../models/user";

interface IListParams {
    transfer: Transfer;
}
const ProviderChoice: React.FunctionComponent<IListParams> = ({ transfer }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [dataPaginate, setDataPaginate] = useState(new DataPaginate());
    const [pageProviders, setPageProviders] = useState(1);
    const [assignments, setAssignments] = useState<Assignment[]>([]);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [checkboxs, setCheckboxs] = useState<any>({
        checkAll: false,
        selections: [],
    });
    const [nbAssignmentAccepted, setNbAssignmentAccepted] = useState<number>(0);

    const fetchAssignments = async () => {
        try {
            const service = new TransferService();
            const assignments = await service.assignments(transfer.id);
            setAssignments(assignments);
            setNbAssignmentAccepted(
                assignments.filter((assignment: Assignment) =>
                    assignment.isAccepted()
                ).length
            );
        } catch (error) {
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    useEffect(() => {
        const service = new TransferService();
        setLoading(true);
        const fetchAssignments = async () => {
            try {
                const assignments = await service.assignments(transfer.id);
                setAssignments(assignments);
                setNbAssignmentAccepted(
                    assignments.filter((assignment: Assignment) =>
                        assignment.isAccepted()
                    ).length
                );

                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrorApi(new ErrorFormData(error.response.data));
            }
        };
        fetchAssignments();
        if (!transfer.isTender()) {
            //Get paginated providers
            const fetchAllInfo = async () => {
                try {
                    setDataPaginate(
                        await service.providers(transfer.id, pageProviders)
                    );
                } catch (error) {
                    setErrorApi(new ErrorFormData(error.response.data));
                }
            };
            setLoading(false);
            fetchAllInfo();
        }
    }, [pageProviders, transfer]);

    //call api - assign provider to a transfer
    const assignProvider = async () => {
        const service = new TransferService();
        try {
            await Promise.all(
                checkboxs.selections.map(async (selec: number) => {
                    await service.assign(transfer.id, {
                        provider_id: selec,
                        status: "pending",
                    });
                })
            );
            toastr.success(t("success"), t("success_provider_assigned"));
            fetchAssignments();
        } catch (error) {
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    //Handle validate provider action
    const validateAction = () => {
        if (checkboxs.selections.length !== 0) {
            assignProvider();
        } else {
            toastr.warning(t("warning"), t("warning_provider_action_transfer"));
        }
    };

    //Handle checkboxs
    const handleCheckboxs = (event: any) => {
        handleCheckBoxChange(event, checkboxs, setCheckboxs, dataPaginate);
    };

    const renderBasicTable = () => {
        return (
            <>
                <FormTitle title={t("provider_choice")} margin={true} />
                {dataPaginate && dataPaginate.data && !loading ? (
                    <>
                        <TableContainer component={Paper}>
                            <Table
                                aria-label="customized table"
                                style={{ overflow: "hidden" }}
                            >
                                {/* HEAD */}
                                <TableHead>
                                    <TableRow>
                                        <HeadTableCell
                                            className="testTh"
                                            align="center"
                                        >
                                            {/* Checkbox to check all items */}
                                            {dataPaginate.data !== undefined &&
                                            dataPaginate.data.length > 1 ? (
                                                <Checkbox
                                                    onChange={(e) =>
                                                        handleCheckboxs(e)
                                                    }
                                                    id="checkAll"
                                                    checked={
                                                        checkboxs.checkAll ||
                                                        false
                                                    }
                                                    inputProps={{
                                                        "aria-label":
                                                            "checkbox with default color",
                                                    }}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("picture")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("name")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("phone")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("email")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("folder_status")}
                                        </HeadTableCell>
                                        {nbAssignmentAccepted > 0 && (
                                            <>
                                                <HeadTableCell align="center">
                                                    {t("response")}
                                                </HeadTableCell>
                                                <HeadTableCell align="center">
                                                    {t("options")}
                                                </HeadTableCell>
                                            </>
                                        )}
                                        <HeadTableCell align="center"></HeadTableCell>
                                    </TableRow>
                                </TableHead>
                                {/* BODY */}
                                <TableBody>
                                    {dataPaginate.data.map(
                                        (provider: Provider) => (
                                            <ItemProviderChoice
                                                key={`table_provider_id_${provider.id}`}
                                                provider={provider}
                                                transfer={transfer}
                                                handleCheckbox={(e) =>
                                                    handleCheckboxs(e)
                                                }
                                                checkboxs={checkboxs}
                                                nbAssignmentAccepted={
                                                    nbAssignmentAccepted
                                                }
                                                assignment={
                                                    assignments.filter(
                                                        (a: any) =>
                                                            a.provider_id ===
                                                                provider.id &&
                                                            a.transfer_id ===
                                                                transfer.id
                                                    )[0]
                                                }
                                                setError={(e: any) =>
                                                    setErrorApi(e)
                                                }
                                                onRefresh={() =>
                                                    fetchAssignments()
                                                }
                                            />
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* PAGINATION */}
                        <Pagination
                            dataPaginate={dataPaginate}
                            onPageChange={(page: number) =>
                                setPageProviders(page)
                            }
                        />
                        {/* DropdownListe actions + validate button */}

                        <Button
                            className="btn-form btn-icon"
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={validateAction}
                            style={{ marginTop: "40px" }}
                            disabled={!transfer.canChoiceProvider()}
                        >
                            <span>{t("price_request")}</span> <Icon>send</Icon>
                        </Button>
                    </>
                ) : loading ? (
                    <CircularProgress />
                ) : dataPaginate.data?.length === 0 ? (
                    <div>{t("no_items")}</div>
                ) : (
                    ""
                )}
                <ErrorForm errorApi={errorApi} />
            </>
        );
    };

    const renderTenderTable = () => {
        return (
            <>
                <FormTitle title={t("provider_choice")} margin={true} />
                {assignments.length > 0 && !loading ? (
                    <>
                        <TableContainer component={Paper}>
                            <Table
                                aria-label="customized table"
                                style={{ overflow: "hidden" }}
                            >
                                {/* HEAD */}
                                <TableHead>
                                    <TableRow>
                                        <HeadTableCell align="center">
                                            {t("picture")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("name")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("phone")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("email")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("driver")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("folder_status")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("response")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center"></HeadTableCell>
                                    </TableRow>
                                </TableHead>
                                {/* BODY */}
                                <TableBody>
                                    {assignments.map((assign: Assignment) => (
                                        <ItemProviderTenderChoice
                                            key={`table_provider_id_${assign.id}`}
                                            assignment={assign}
                                            transfer={transfer}
                                            setError={(e: any) =>
                                                setErrorApi(e)
                                            }
                                            provider={assign.provider}
                                            onRefresh={() => fetchAssignments()}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* PAGINATION */}
                        <Pagination
                            dataPaginate={dataPaginate}
                            onPageChange={(page: number) =>
                                setPageProviders(page)
                            }
                        />
                        {/* DropdownListe actions + validate button */}

                        <Button
                            className="btn-form btn-icon"
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={validateAction}
                            style={{ marginTop: "40px" }}
                            disabled={!transfer.canChoiceProvider()}
                        >
                            <span>{t("price_request")}</span> <Icon>send</Icon>
                        </Button>
                    </>
                ) : loading ? (
                    <CircularProgress />
                ) : (
                    <div>{t("no_items")}</div>
                )}
                <ErrorForm errorApi={errorApi} />
            </>
        );
    };
    return transfer.isTender() ? renderTenderTable() : renderBasicTable();
};

export default ProviderChoice;
