import React, { useEffect } from "react";
import FormSection from "../../../../components/form-section/FormSection";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import { Transfer } from "../../../../models/transfer";
import { useTranslation } from "react-i18next";
import AutocompleteControlled from "../../../../components/fields-controlled/AutocompleteControlled";
import { Driver } from "../../../../models/driver";
import InfoCardDrivers from "../InfoCards/InfoCardDrivers";
import { Assignment } from "../../../../models/assignment";

interface Iprops {
    transfer: Transfer;
    drivers: Driver[];
    setValue: (e: any, e2: any) => void;
    control: any;
    errors: any;
    disabledForm: any;
    assignment?: Assignment;
    driverChoice?: Driver | null;
    setDriverChoice: any;
}

const FormPropositionTender = ({
    setValue,
    transfer,
    control,
    errors,
    disabledForm,
    drivers,
    assignment,
    driverChoice,
    setDriverChoice,
}: Iprops) => {
    const { t } = useTranslation();

    const updateDriver = (selected: any) => {
        setDriverChoice(selected);
        setValue("driver_id", selected.id);
    };

    const removeDriver = () => {
        setDriverChoice(null);
        setValue("driver_id", null);
        setValue("driver", null);
    };
    useEffect(() => {
        assignment && setDriverChoice(assignment?.response?.driver);
    }, [assignment, setDriverChoice]);
    const getAmount = () => {
        if (assignment?.response) return assignment.response.price;
        return transfer.tender_price
            ? transfer.tender_price
            : transfer.min_estimate;
    };
    return (
        <div className="form_row">
            <div className="response col-6">
                <FormSection title={t("your_response")}>
                    <div className="field_price_section">
                        <div className="field-form field_price">
                            <InputControlled
                                name="price"
                                label={t("form.price")}
                                value={getAmount()}
                                required={
                                    transfer.tender_price ||
                                    transfer.tender_status === "validated"
                                        ? false
                                        : true
                                }
                                disabled={
                                    transfer.tender_price ||
                                    transfer.tender_status === "validated"
                                        ? true
                                        : false
                                }
                                control={control}
                                errors={errors}
                                decorator={
                                    <span className="decorator">
                                        {t("money_symbol")}
                                    </span>
                                }
                            />
                        </div>
                    </div>

                    {drivers && !driverChoice ? (
                        <AutocompleteControlled
                            label={t("drivers")}
                            name="driver"
                            disabled={disabledForm}
                            listItems={drivers ? drivers : []}
                            value={driverChoice}
                            required={true}
                            getOptionLabel={(option: Driver, value: any) =>
                                option.last_name + " " + option.first_name
                            }
                            control={control}
                            errors={errors}
                            onChange={(driver: any) => updateDriver(driver)}
                        />
                    ) : drivers && driverChoice ? (
                        <div>
                            <InfoCardDrivers
                                item={driverChoice}
                                remove={removeDriver}
                            />
                        </div>
                    ) : (
                        t("no_drivers")
                    )}
                </FormSection>
            </div>
        </div>
    );
};

export default FormPropositionTender;
